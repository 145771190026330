import React from 'react';

const SocialLinks = props => {
    if (!props.links) return <></>;
    return (
        <div className={props.className}>
            {props.links['facebook'] && <a href={props.links['facebook']}
                                           target="_blank"
                                           rel="noopener noreferrer">Youtube</a>}
            {props.links['instagram'] && <a href={props.links['instagram']}
                                            target="_blank"
                                            rel="noopener noreferrer">Instagram</a>}
            {props.links['vkonakte'] && <a href={props.links['vkonakte']}
                                           target="_blank"
                                           rel="noopener noreferrer">Vkontakte</a>}
        </div>
    );
};

export default SocialLinks;
