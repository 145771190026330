import React from 'react';
import {Loading} from '../../App';
import GlideSlider from '../GlideSlider';

class CulturePage extends React.Component {
    constructor(props) {
        super(props);
        this.props.extra.onPageChange('culture');
        this.needUpdate = true;
    }
    
    shouldComponentUpdate() {
        return this.needUpdate;
    }
    
    parsePhotos(albums) {
        let photos = {};
        albums.forEach((item, i) => {
            photos[i] = [];
            for (let key in item.images) {
                if (!item.images.hasOwnProperty(key)) continue;
                if (!item.images[key]) continue;
                if (!item.images[key].length) continue;
                photos[i].push(item.images[key]);
            }
        });
        return photos;
    }
    
    render() {
        if (!this.props.extra.content) return <Loading inline={true} />;
        this.needUpdate = false;
        const albums = this.props.extra.content.blocks.albums.blocks,
            photos = this.parsePhotos(albums);
        if (!albums.length) return <></>;
        return (
            <section className="culture">
                <CultureAlbums albums={albums} photos={photos} />
            </section>
        );
    }
}

class CultureAlbums extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentAlbum: 0
        };
        this.glideSlider = null;
        this.getGlideSlider = this.getGlideSlider.bind(this);
    }
    
    componentWillUnmount() {
        this.removeEvents();
    }
    
    albumChange(direction) {
        const albumCount = this.props.albums.length;
        let {currentAlbum} = this.state;
        if (direction === 'next') {
            currentAlbum++;
        } else if (direction === 'prev') {
            currentAlbum--;
        } else {
            currentAlbum = direction;
        }
        if (currentAlbum < 0) currentAlbum = albumCount - 1;
        if (currentAlbum >= albumCount) currentAlbum = 0;
        this.glideSlider.go(`=${currentAlbum}`);
        document.querySelectorAll('.culture-item').forEach(el => {
            el.setAttribute('data-active', 'false');
            if (parseInt(el.getAttribute('data-index'), 10) === currentAlbum) el.setAttribute('data-active', 'true');
        });
        this.setState({
            currentAlbum: currentAlbum
        });
    }
    
    getGlideSlider(slider) {
        this.glideSlider = slider;
        this.addEvents();
    }
    
    addEvents() {
        this.eventListenerClick = (e) => {
            const elItem = e.target.closest('.culture-item');
            if (!elItem) return;
            this.albumChange(parseInt(elItem.getAttribute('data-index'), 10));
        };
        window.addEventListener('click', this.eventListenerClick);
    }
    
    removeEvents() {
        window.removeEventListener('click', this.eventListenerClick);
    }
    
    render() {
        const {currentAlbum} = this.state,
            albums = this.props.albums,
            album = albums[currentAlbum],
            photos = this.props.photos,
            photo = photos[currentAlbum][0];
        return (
            <>
                <div className="culture__row">
                    <div className="culture__body">
                        <div className="culture__info">
                            {album.tags &&
                            <div className="label" style={{background: album.tags_color}}>{album.tags}</div>}
                            {album.title &&
                            <div className="culture__title h1">{album.title}</div>}
                            {album.short_description &&
                            <div className="culture__tag">{album.short_description}</div>}
                            {album.full_description &&
                            <div className="culture__text">{album.full_description}</div>}
                        </div>
                        <div className="culture__arrows">
                            {albums.length > 1 &&
                            <>
                                <div className="button-arrow button-arrow_light button-arrow_prev" onClick={() => this.albumChange('prev')} />
                                <div className="button-arrow button-arrow_light button-arrow_next" onClick={() => this.albumChange('next')} />
                            </>}
                        </div>
                    </div>
                    <div className="culture__album" style={{backgroundImage: `url(${photo})`}}>
                        <img src={photo} alt="" />
                        {photos[currentAlbum].length > 0 &&
                        <a className="button" href="/" data-open_popup="photos">Смотреть фотографии</a>}
                        {albums.length > 1 &&
                        <>
                            <div className="button-arrow button-arrow_prev" onClick={() => this.albumChange('prev')} />
                            <div className="button-arrow button-arrow_next" onClick={() => this.albumChange('next')} />
                        </>}
                    </div>
                </div>
    
                {albums.length > 1 &&
                <GlideSlider className="culture" getSlider={this.getGlideSlider}>
                    <div className="glide__track" data-glide-el="track">
                        <div className="glide__slides">
                            {albums.map((item, i) => (
                                <div className="glide__slide" key={i}>
                                    <div className="culture-item" data-index={i} data-active={i === 0}>
                                        <div className="culture-item__photo" style={{backgroundImage: `url(${photos[i][0]})`}}>
                                            <div className="culture-item__photo-handle" />
                                            {item.tags &&
                                            <div className="label" style={{background: item.tags_color}}>{item.tags}</div>}
                                        </div>
                                        <div className="culture-item__title">{item.title}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                    <div className="glide__bullets" data-glide-el="controls[nav]">
                        {albums.map((item, i) => <div className="glide__bullet" data-glide-dir={`=${i}`} key={i} />)}
                    </div>
                </GlideSlider>}
    
                {photos[currentAlbum].length > 0 && <CulturePhotos photos={photos[currentAlbum]} />}
            </>
        );
    }
}

const CulturePhotos = props => {
    return (
        <div className="popup popup-photos">
            <GlideSlider className="photos">
                {props.photos.length > 1 &&
                <div className="glide__arrows" data-glide-el="controls">
                    <div className="glide__arrow glide__arrow--left" data-glide-dir="<" />
                    <div className="glide__arrow glide__arrow--right" data-glide-dir=">" />
                </div>}
                
                <div className="glide__track" data-glide-el="track">
                    <div className="glide__slides">
                        {props.photos.map((item, i) => (
                            <div className="glide__slide" key={i}>
                                <div className="popup-photos__close" data-close_popup="" />
                                <div className="popup-photos__img-wrap">
                                    <div className="popup__close" data-close_popup="" />
                                    <img className="popup-photos__img" src={item} alt="" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </GlideSlider>
        </div>
    );
};

export default CulturePage;
