import React from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as IconPencil} from '../assets/img/icon_pencil.svg';
import {ReactComponent as IconMapPoint} from '../assets/img/icon_map_point.svg';

class Header extends React.Component {
    shouldComponentUpdate(nextProps) {
        return this.props.currentCity.en !== nextProps.currentCity.en;
    }
    
    render() {
        return (
            <header className="header">
                <div className="content-layout">
                    <div className="header__logo">
                        <Link to="/" />
                    </div>
                    <div className="header__menu-trigger" data-toggle_popup="menu">
                        <div />
                    </div>
                    <div className="header__btn-profile button button_bordered" data-toggle_popup="profile" data-kind="header">Заполнить анкету</div>
                    <CitySelect city={this.props.currentCity}
                                cityList={this.props.cityAvailableList}
                                onChange={this.props.onCityChange} />
                    <a className="header__btn-profile-mobile" data-toggle_popup="profile" data-kind="header" href="/">
                        <IconPencil />
                    </a>
                    <a className="header__city-select-mobile" data-open_popup="city-select" href="/">
                        <IconMapPoint />
                    </a>
                </div>
            </header>
        );
    }
}

const CitySelect = props => {
    let listItems = [];
    if (props.cityList.length > 1) {
        props.cityList.forEach(item => {
            if (item.en === props.city.en) return;
            listItems.push(
                <div className="input-select__list-item" key={item.en}
                     onClick={() => props.onChange({en: item.en, ru: item.ru})}>
                    {item.ru}
                </div>
            );
        });
    }
    return (
        <div className={`header__city-select input-select${(props.cityList.length < 2) ? ' input-select_disabled' : ''}`}>
            <div className="input-select__text">{props.city.ru}</div>
            <div className="input-select__list" data-custom_scroll="">
                <div>{listItems}</div>
            </div>
        </div>
    );
};

export default Header;
