import React from 'react';
import {Loading} from '../../App';
import IconCake from '../../assets/img/icon_cake.svg';
import IconDollar from '../../assets/img/icon_dollar.svg';
import IconSmile from '../../assets/img/icon_smile.svg';
import GlideSlider from '../GlideSlider';

class AdvantagesPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.extra.onPageChange('advantages');
        this.needUpdate = true;
    }
    
    shouldComponentUpdate() {
        return this.needUpdate;
    }
    
    render() {
        if (!this.props.extra.content) return <Loading inline={true} />;
        this.needUpdate = false;
        const advantages = this.props.extra.content.blocks.advantages,
            globalStory = this.props.extra.content.blocks.global_story.blocks,
            successStory = this.props.extra.content.blocks.success_story;
        return (
            <section className="advantages">
                <AdvantagesFacts title={advantages.title}
                                 text={advantages.short_description}
                                 facts={advantages.blocks} />
                
                {globalStory.length > 0 &&
                <AdvantagesStory items={globalStory} />}
                
                <AdvantagesStories title={successStory.title}
                                   text={successStory.short_description}
                                   stories={successStory.blocks} />
            </section>
        );
    }
}

const AdvantagesFacts = props => {
    return (
        <div className="advantages-facts">
            <div className="content-layout">
                <div className="advantages-facts__title h1">{props.title}</div>
                <div className="advantages-facts__text">{props.text}</div>
            </div>
            
            {props.facts.length > 0 && <AdvantagesFactsSlider facts={props.facts} />}
        </div>
    );
};

export class AdvantagesFactsSlider extends React.Component {
    constructor(props) {
        super(props);
        this.icons = [
            {
                icon: IconSmile,
                background: '#ffc000'
            }, {
                icon: IconCake,
                background: '#b6cd73'
            }, {
                icon: IconDollar,
                background: '#66aaa9'
            }
        ];
        this.currentIndex = 0;
    }
    
    componentDidMount() {
        this.addEvents();
    }
    
    shouldComponentUpdate() {
        return false;
    }
    
    addEvents() {
        this.elSliderNavItems = document.querySelectorAll('.advantages-facts-slider-nav__item');
        this.elSliderItems = document.querySelectorAll('.advantages-facts-slider__item');
        this.elSliderNavItems.forEach((el, i) => {
            el.addEventListener('mouseenter', () => {
                this.setSlide(i);
            });
            el.addEventListener('click', () => {
                this.setSlide(i);
            });
        });
    }
    
    setSlide(index) {
        if (index === this.currentIndex) return;
        this.currentIndex = index;
        this.elSliderItems.forEach((el, i) => {
            el.setAttribute('data-active', index === i);
        });
        this.elSliderNavItems.forEach((el, i) => {
            el.setAttribute('data-active', index === i);
        });
    }
    
    render() {
        return (
            <>
                <div className="advantages-facts-slider">
                    <div className="advantages-facts-slider-nav">
                        {this.props.facts.map((item, i) => (
                            <div className="advantages-facts-slider-nav__item" data-active={!i} key={i}>
                                <div className="advantages-facts-slider-nav__icon circle-icon" style={{background: this.icons[i].background}}>
                                    <img src={this.icons[i].icon} alt="" />
                                </div>
                                <div className="advantages-facts-slider-nav__number">{item.title}</div>
                                <div className="advantages-facts-slider-nav__text">{item.short_description}</div>
                            </div>
                        ))}
                    </div>
                    {this.props.facts.map((item, i) => (
                        <div className="advantages-facts-slider__item" data-active={!i} style={{backgroundImage: `url(${item.image})`}} key={i} />
                    ))}
                </div>
                
                <GlideSlider className="facts">
                    <div className="glide__track" data-glide-el="track">
                        <div className="glide__slides">
                            {this.props.facts.map((item, i) => (
                                <div className="glide__slide" key={i}>
                                    <div className="advantages-facts-item">
                                        <div className="advantages-facts-item__icon circle-icon" style={{background: this.icons[i].background}}>
                                            <img src={this.icons[i].icon} alt="" />
                                        </div>
                                        <div className="advantages-facts-item__number">{item.title}</div>
                                        <div className="advantages-facts-item__text">{item.short_description}</div>
                                        <img className="advantages-facts-item__photo" src={item.image_mobile} alt="" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                    <div className="glide__bullets" data-glide-el="controls[nav]">
                        {this.props.facts.map((item, i) => <div className="glide__bullet" data-glide-dir={`=${i}`} key={i} />)}
                    </div>
                </GlideSlider>
            </>
        );
    }
}

class AdvantagesStory extends React.Component {
    constructor(props) {
        super(props);
        this.currentIndex = 0;
        this.countSlides = this.props.items.length;
    }
    
    componentDidMount() {
        this.addEvents();
    }
    
    addEvents() {
        this.elArrows = document.querySelectorAll('.advantages-story_slider .button-arrow');
        this.elInfo = document.querySelector('.advantages-story_slider .advantages-story__info');
        this.elInfoItems = document.querySelectorAll('.advantages-story_slider .advantages-story__info-item');
        this.elAlbumImgs = document.querySelectorAll('.advantages-story_slider .advantages-story__album-img');
        this.elArrows.forEach(el => {
            el.addEventListener('click', () => {
                el.classList.contains('button-arrow_prev')
                    ? this.setSlide(this.currentIndex - 1) : this.setSlide(this.currentIndex + 1);
            });
        });
    }
    
    setSlide(index) {
        if (index >= this.countSlides) index = 0;
        if (index < 0) index = this.countSlides - 1;
        if (index === this.currentIndex) return;
        this.currentIndex = index;
        this.elInfoItems.forEach((el, i) => {
            el.setAttribute('data-active', index === i);
        });
        this.elAlbumImgs.forEach((el, i) => {
            el.setAttribute('data-active', index === i);
        });
        if (this.elInfo.Scroll) this.elInfo.Scroll.update();
    }
    
    render() {
        return (
            <>
                <div className="advantages-story advantages-story_slider">
                    <div className="advantages-story__row">
                        <div className="advantages-story__body">
                            <div className="advantages-story__info" data-custom_scroll="">
                                {this.props.items.map((item, i) => (
                                    <div className="advantages-story__info-item" data-active={!i} key={i}>
                                        <div className="advantages-story__title h1">{item.title}</div>
                                        <div className="advantages-story__text">{item.short_description}</div>
                                    </div>
                                ))}
                            </div>
                            {this.props.items.length > 1 &&
                            <div className="advantages-story__arrows">
                                <div className="button-arrow button-arrow_light button-arrow_prev" />
                                <div className="button-arrow button-arrow_light button-arrow_next" />
                            </div>}
                        </div>
                        <div className="advantages-story__album">
                            {this.props.items.map((item, i) => (
                                <div className="advantages-story__album-img" style={{backgroundImage: `url(${item.image})`}} data-active={!i} key={i} />
                            ))}
                        </div>
                    </div>
                </div>
                
                <GlideSlider className="story">
                    {this.props.items.length > 1 &&
                    <div className="glide__arrows" data-glide-el="controls">
                        <div className="glide__arrow glide__arrow--left" data-glide-dir="<" />
                        <div className="glide__arrow glide__arrow--right" data-glide-dir=">" />
                    </div>}
                    
                    <div className="glide__track" data-glide-el="track">
                        <div className="glide__slides">
                            {this.props.items.map((item, i) => (
                                <div className="glide__slide" key={i}>
                                    <div className="advantages-story">
                                        <div className="advantages-story__row">
                                            <div className="advantages-story__body">
                                                <div className="advantages-story__info">
                                                    <div className="advantages-story__title h1">{item.title}</div>
                                                    <div className="advantages-story__text">{item.short_description}</div>
                                                </div>
                                            </div>
                                            <div className="advantages-story__album" data-glide-el="controls">
                                                <img src={item.image} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                    <div className="glide__bullets" data-glide-el="controls[nav]">
                        {this.props.items.map((item, i) => <div className="glide__bullet" data-glide-dir={`=${i}`} key={i} />)}
                    </div>
                </GlideSlider>
            </>
        );
    }
}

class AdvantagesStories extends React.Component {
    constructor(props) {
        super(props);
        this.glideSliderStories = null;
        this.glideSliderPopup = null;
        this.getGlideSliderStories = this.getGlideSliderStories.bind(this);
        this.getGlideSliderPopup = this.getGlideSliderPopup.bind(this);
    }
    
    componentWillUnmount() {
        this.removeEvents();
    }
    
    storiesChange(index) {
        this.glideSliderPopup.go('=' + index);
        this.glideSliderStories.go('=' + index);
    }
    
    getGlideSliderStories(slider) {
        this.glideSliderStories = slider;
        this.addEvents();
    }
    
    getGlideSliderPopup(slider) {
        this.glideSliderPopup = slider;
        this.addEvents();
    }
    
    addEvents() {
        if (!this.eventListenerClick) {
            this.eventListenerClick = (e) => {
                const elLink = e.target.closest('a[data-open_popup="stories"]');
                if (!elLink) return;
                this.storiesChange(parseInt(elLink.getAttribute('data-index'), 10));
            };
            window.addEventListener('click', this.eventListenerClick);
        }
        if (this.glideSliderPopup) {
            this.glideSliderPopup.on('run', () => {
                this.storiesChange(this.glideSliderPopup.index);
            });
        }
    }
    
    removeEvents() {
        window.removeEventListener('click', this.eventListenerClick);
    }
    
    render() {
        return (
            <div className="advantages-stories">
                <div className="content-layout">
                    <div className="advantages-stories__title h1">{this.props.title}</div>
                    <div className="advantages-stories__text">{this.props.text}</div>
                </div>
                
                {this.props.stories.length > 0 &&
                <GlideSlider className="stories" getSlider={this.getGlideSliderStories}>
                    <div className="glide__track" data-glide-el="track">
                        <div className="glide__slides">
                            {this.props.stories.map((item, i) => (
                                <div className="glide__slide" style={{backgroundImage: `url(${item.image})`}} key={i}>
                                    <div />
                                    <a href="/" data-open_popup="stories" data-index={i}> </a>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                    <div className="glide__bullets" data-glide-el="controls[nav]">
                        {this.props.stories.map((item, i) => <div className="glide__bullet" data-glide-dir={`=${i}`} key={i} />)}
                    </div>
                </GlideSlider>}
                
                <a className="advantages-stories__popup-bg" href="/" data-close_popup=""> </a>
                <div className="popup popup-stories">
                    <GlideSlider className="photos" getSlider={this.getGlideSliderPopup}>
                        {this.props.stories.length > 1 &&
                        <div className="glide__arrows" data-glide-el="controls">
                            <div className="glide__arrow glide__arrow--left" data-glide-dir="<" />
                            <div className="glide__arrow glide__arrow--right" data-glide-dir=">" />
                        </div>}
                        
                        <div className="glide__track" data-glide-el="track">
                            <div className="glide__slides">
                                {this.props.stories.map((item, i) => (
                                    <div className="glide__slide" key={i}>
                                        <div className="popup-stories__close" data-close_popup="" />
                                        <div className="popup-stories__item">
                                            <div className="popup__close" data-close_popup="" />
                                            <div className="popup__wrap" data-custom_scroll="">
                                                <div className="show-tablet">
                                                    <div className="popup-stories__title">{item.title}</div>
                                                    <div className="popup-stories__post">{item.short_description}</div>
                                                </div>
                                                <div className="popup-stories__row">
                                                    <img className="popup-stories__photo" src={item.image} alt="" />
                                                    <div className="popup-stories__body">
                                                        <div className="hide-tablet">
                                                            <div className="popup-stories__title">{item.title}</div>
                                                            <div className="popup-stories__post">{item.short_description}</div>
                                                        </div>
                                                        <div className="popup-stories__text" dangerouslySetInnerHTML={{__html: item.full_description}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </GlideSlider>
                </div>
            </div>
        );
    }
}

export default AdvantagesPage;
