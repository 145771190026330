import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Loading} from '../../App';

class VacancyDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.extra.onPageChange('vacancy');
        this.isSyncVacancy = false;
        this.timeoutSync = null;
        this.timeoutSyncDuration = 500;
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) this.isSyncVacancy = false;
        if (this.props.extra.vacancy) this.syncVacancy();
    }
    
    componentWillUnmount() {
        clearTimeout(this.timeoutSync);
    }
    
    syncVacancy() {
        if (this.isSyncVacancy) return;
        const {vacancy, localCity, onVacancySync} = this.props.extra,
            currentVacancy = vacancy.list[vacancy.matchList[parseInt(this.props.match.params.id, 10)]];
        if (localCity.en !== currentVacancy.city) {
            return onVacancySync({
                localCity: {en: currentVacancy.city, ru: currentVacancy.city_rus}
            });
        }
        clearTimeout(this.timeoutSync);
        this.timeoutSync = setTimeout(() => {
            onVacancySync({
                vacancyValue: currentVacancy.title,
                addressValue: currentVacancy.type === 'office' ? 'Любой офис' : currentVacancy.address
            });
        }, this.timeoutSyncDuration);
        this.isSyncVacancy = true;
    }
    
    render() {
        if (!this.props.extra.vacancy) return <Loading inline={true} />;
        const vacancy = this.props.extra.vacancy,
            currentId = parseInt(this.props.match.params.id, 10),
            currentVacancy = vacancy.list[vacancy.matchList[currentId]];
        if (!currentVacancy) return <Redirect to="/vacancy" />;
        const currentCategory = vacancy.category.list[vacancy.category.matchList[currentVacancy.category]] || null;
        return (
            <>
                <nav className="navigation">
                    <div className="content-layout">
                        <ul className="navigation__list">
                            <li className="navigation__item"><Link to="/">Главная</Link></li>
                            <li className="navigation__item"><Link to="/vacancy">Вакансии</Link></li>
                            <li className="navigation__item">{currentVacancy.title}</li>
                        </ul>
                    </div>
                </nav>
                
                <section className="vacancy-detail">
                    <div className="content-layout">
                        <div className="vacancy-detail__row">
                            <div className="vacancy-detail__body">
                                <div className="vacancy-detail__title h1">{currentVacancy.title}</div>
                                <div className="label">{currentVacancy.city_rus}</div>
                                {currentVacancy.type !== 'office' &&
                                <div className="vacancy-detail__address">{currentVacancy.address}</div>}
                                <div className="vacancy-detail__cost">
                                    {currentVacancy.salary &&
                                    `з/п ${currentVacancy.salary.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')} руб`}
                                </div>
                                <a className="button button_green" href="/" data-open_popup="profile" data-kind="vacancy">Откликнуться</a>
                                <div className="vacancy-detail__text" dangerouslySetInnerHTML={{__html: currentVacancy.description}} />
                            </div>
                            {currentCategory &&
                            <div className="vacancy-item">
                                {currentCategory.photo &&
                                <div className="vacancy-item-photo" style={{color: currentCategory.icon_color}}>
                                    <img className="vacancy-item-photo__img" src={currentCategory.photo} alt="" />
                                    <div className="vacancy-item-photo__icon circle-icon">
                                        <img src={currentCategory.icon} alt="" />
                                    </div>
                                </div>}
                                <div className="vacancy-item__title">{currentCategory.title}</div>
                                <div className="vacancy-item__text" dangerouslySetInnerHTML={{__html: currentCategory.description}} />
                            </div>}
                        </div>
                        
                        <VacancyDetailPageSimilar vacancy={vacancy}
                                                  currentVacancy={currentVacancy}
                                                  currentId={currentId} />
                    </div>
                </section>
            </>
        );
    }
}

class VacancyDetailPageSimilar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1
        };
        this.itemsPerPage = 4;
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.currentId !== prevProps.currentId) {
            this.setState({
                currentPage: 1
            });
        }
    }
    
    nextPage(e) {
        e.preventDefault();
        let {currentPage} = this.state;
        currentPage++;
        this.setState({
            currentPage: currentPage
        });
    }
    
    getSimilar() {
        const {vacancy, currentVacancy, currentId} = this.props;
        let itemsList = [];
        currentVacancy.similar.forEach((id, i) => {
            if (id === currentId) return;
            let vacancySimilarItem = vacancy.list[vacancy.matchList[id]];
            if (!vacancySimilarItem) return;
            itemsList.push(
                <div className="col-12 col-sm-6 col-xl-3" key={i}>
                    <div className="vacancy-list-item">
                        <div className="vacancy-list-item__icon circle-icon" style={{background: vacancySimilarItem.color}}>
                            <img src={vacancySimilarItem.icon} alt="" />
                        </div>
                        <div className="label">{vacancySimilarItem.city_rus}</div>
                        {vacancySimilarItem.type !== 'office' &&
                        <div className="vacancy-list-item__address">{vacancySimilarItem.address}</div>}
                        <div className="vacancy-list-item__title">
                            <Link to={`/vacancy/${id}`}>{vacancySimilarItem.title}</Link>
                        </div>
                        <div className="vacancy-list-item__text" dangerouslySetInnerHTML={{__html: vacancySimilarItem.short_description}} />
                    </div>
                </div>
            );
        });
        return itemsList;
    }
    
    render() {
        const itemsLimit = this.itemsPerPage * this.state.currentPage,
            itemsList = this.getSimilar();
        if (!itemsList.length) return <></>;
        return (
            <>
                <div className="vacancy-detail__subtitle vacancy-detail__subtitle_similar">Похожие вакансии</div>
                <div className="vacancy-list">
                    <div className="row">{itemsList.slice(0, itemsLimit)}</div>
                    {itemsList.length > itemsLimit &&
                    <div className="vacancy-list__more">
                        <a className="button button_bordered" href="/" onClick={(e) => this.nextPage(e)}>Загрузить еще</a>
                    </div>}
                </div>
            </>
        );
    }
}

export default VacancyDetailPage;
