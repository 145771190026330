import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Loading} from '../../App';
import GlideSlider from '../GlideSlider';
import VacancyItem1 from '../../assets/img/vacancy_item_1.png';
import IconSmile from '../../assets/img/icon_smile.svg';

class VacancyPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.extra.onPageChange('vacancy');
        this.state = {
            restaurantAddress: 'Ресторан'
        };
        this.isResetFilter = false;
        this.timeoutScrollTo = null;
        this.timeoutScrollToDuration = 300;
        this.locationParams = {
            category: null,
            office: false
        };
        this.addressChange = this.addressChange.bind(this);
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.extra.localCity.en === prevProps.extra.localCity.en) return;
        this.isResetFilter = true;
        this.setState({
            restaurantAddress: 'Ресторан'
        });
    }
    
    componentWillUnmount() {
        clearTimeout(this.timeoutScrollTo);
    }
    
    parseLocation() {
        let search = window.location.search,
            params = {};
        if (!search.length) return {category: null, office: false};
        search = search.substr(1).split('&');
        search.forEach(item => {
            let searchSplit = item.split('=');
            params[searchSplit[0]] = searchSplit[1] || true;
        });
        return params;
    }
    
    scrollToItems() {
        clearTimeout(this.timeoutScrollTo);
        this.timeoutScrollTo = setTimeout(() => {
            const elTarget = document.querySelector('.vacancy-list') || document.querySelector('.vacancy-empty');
            if (elTarget) {
                window.scroll({
                    top: elTarget.offsetTop - 150,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }, this.timeoutScrollToDuration);
    }
    
    addressChange(addressValue) {
        this.setState({
            restaurantAddress: addressValue
        });
        this.scrollToItems();
    }
    
    render() {
        const locationParams = this.parseLocation();
        if (this.isResetFilter) {
            this.isResetFilter = false;
            return <Redirect to={`/vacancy${(locationParams.office) ? '?office' : ''}`} />;
        }
        if (!this.props.extra.content || !this.props.extra.vacancy) return <Loading inline={true} />;
        const currentCityDeclension = this.props.extra.currentCityDeclension,
            localCity = this.props.extra.localCity,
            content = this.props.extra.content.blocks.vacancy_list,
            vacancy = this.props.extra.vacancy,
            {restaurantAddress} = this.state;
        if (this.locationParams.office !== locationParams.office || this.locationParams.category !== locationParams.category) this.scrollToItems();
        this.locationParams = locationParams;
        return (
            <section className="vacancy">
                <div className="content-layout">
                    <div className="vacancy__title h1">
                        Вакансии в <a href="/" data-open_popup="city-select-local">{currentCityDeclension}</a>
                    </div>
                    <div className="vacancy__text">{content.short_description}</div>
                    
                    {vacancy.list.length > 0 &&
                    <VacancyFilter locationParams={locationParams}
                                   currentAddress={restaurantAddress}
                                   addressList={vacancy.restaurantAddresses}
                                   onAddressChange={this.addressChange} />}
                </div>
                
                {vacancy.list.length > 0 ? (
                    <>
                        <VacancyPageCategory locationParams={locationParams}
                                             city={localCity}
                                             categoryList={vacancy.category.list} />
                        
                        <VacancyPageList locationParams={locationParams}
                                         vacancyList={vacancy.list}
                                         url={this.props.match.url}
                                         currentAddress={restaurantAddress} />
                    </>
                ) : (<VacancyEmpty />)}
            </section>
        );
    }
}

const VacancyFilter = props => {
    const isOffice = props.locationParams.office,
        category = props.locationParams.category;
    let invertSearchParams = '',
        addressList = [];
    if (!isOffice) {
        invertSearchParams = `?office${category ? `&category=${category}` : ''}`;
    } else if (category) invertSearchParams = `?category=${category}`;
    if (props.currentAddress !== 'Ресторан' && props.currentAddress !== 'Любой ресторан') addressList.push('Любой ресторан');
    props.addressList.forEach(item => {
        if (item === props.currentAddress) return;
        addressList.push(item);
    });
    return (
        <div className="vacancy__form">
            <Link className={`vacancy__form-btn button${isOffice ? '' : ' button_bordered'}`} replace={true} to={{
                pathname: '/vacancy',
                search: invertSearchParams
            }}>Хочу работать в офисе</Link>
            <div className="input__wrap">
                {isOffice ? (<input className="input-text" type="text" value="Любой офис" readOnly={true} />) : (
                    <div className="input-select">
                        <div className="input-select__text">{props.currentAddress}</div>
                        <div className="input-select__list" data-custom_scroll="">
                            <div>
                                {addressList.map((item, i) =>
                                    <div className="input-select__list-item" key={i} onClick={() => props.onAddressChange(item)}>{item}</div>)}
                            </div>
                        </div>
                    </div>)}
            </div>
        </div>
    );
};

class VacancyPageCategory extends React.Component {
    constructor(props) {
        super(props);
        this.cityEn = this.props.city.en;
    }
    
    render() {
        const {locationParams, city, categoryList} = this.props,
            update = this.cityEn !== this.props.city.en;
        this.cityEn = this.props.city.en;
        return (
            <GlideSlider className="vacancy" doUpdate={update}>
                <div className="glide__track" data-glide-el="track">
                    <div className="glide__slides">
                        <div className="glide__slide">
                            <div className="vacancy-item" data-active={!locationParams.category}>
                                <Link className="vacancy-item__link" replace={true} to={{
                                    pathname: '/vacancy',
                                    search: (locationParams.office ? '?office' : '')
                                }} />
                                <div className="vacancy-item-photo" style={{color: '#9c1614'}}>
                                    <img className="vacancy-item-photo__img" src={VacancyItem1} alt="" />
                                    <div className="vacancy-item-photo__icon circle-icon">
                                        <img src={IconSmile} alt="" />
                                    </div>
                                </div>
                                <div className="label">{city.ru}</div>
                                <div className="vacancy-item__title">Все вакансии</div>
                                <div className="vacancy-item__text">Все вакансии</div>
                            </div>
                        </div>
                        {categoryList.map((item, i) => (
                            <div className="glide__slide" key={i}>
                                <div className="vacancy-item" data-active={parseInt(locationParams.category, 10) === item.id}>
                                    <Link className="vacancy-item__link" replace={true} to={{
                                        pathname: '/vacancy',
                                        search: (locationParams.office ? `?office&category=${item.id}` : `?category=${item.id}`)
                                    }} />
                                    {item.photo &&
                                    <div className="vacancy-item-photo" style={{color: item.icon_color}}>
                                        <img className="vacancy-item-photo__img" src={item.photo} alt="" />
                                        <div className="vacancy-item-photo__icon circle-icon">
                                            <img src={item.icon} alt="" />
                                        </div>
                                    </div>}
                                    <div className="label">{city.ru}</div>
                                    <div className="vacancy-item__title">{item.title}</div>
                                    <div className="vacancy-item__text" dangerouslySetInnerHTML={{__html: item.description}} />
                                </div>
                            </div>
                        ))}
                        <div />
                    </div>
                </div>
                
                <div className="glide__bullets" data-glide-el="controls[nav]">
                    <div className="glide__bullet" data-glide-dir="=0" />
                    {categoryList.map((item, i) => <div className="glide__bullet" data-glide-dir={`=${i + 1}`} key={i} />)}
                </div>
            </GlideSlider>
        );
    }
}

class VacancyPageList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1
        };
        this.itemsPerPage = 8;
    }
    
    nextPage(e) {
        e.preventDefault();
        let {currentPage} = this.state;
        currentPage++;
        this.setState({
            currentPage: currentPage
        });
    }
    
    getVacancyItems() {
        const restaurantAddress = this.props.currentAddress,
            locationParams = this.props.locationParams;
        let items = [];
        this.props.vacancyList.forEach((item, i) => {
            if (locationParams.office && item.type !== 'office') return;
            if (!locationParams.office && item.type === 'office') return;
            if (item.type !== 'office' && restaurantAddress !== 'Ресторан' && restaurantAddress !== 'Любой ресторан' && item.address !== restaurantAddress) return;
            if (locationParams.category && item.category !== locationParams.category) return;
            items.push(
                <div className="col-12 col-sm-6 col-xl-3" key={i}>
                    <div className="vacancy-list-item">
                        <div className="vacancy-list-item__icon circle-icon" style={{background: item.color}}>
                            <img src={item.icon} alt="" />
                        </div>
                        <div className="label">{item.city_rus}</div>
                        {item.type !== 'office' &&
                        <div className="vacancy-list-item__address">{item.address}</div>}
                        <div className="vacancy-list-item__title">
                            <Link to={`${this.props.url}/${item.id}`}>{item.title}</Link>
                        </div>
                        <div className="vacancy-list-item__text" dangerouslySetInnerHTML={{__html: item.short_description}} />
                    </div>
                </div>
            );
        });
        return items;
    }
    
    render() {
        const itemsLimit = this.itemsPerPage * this.state.currentPage,
            itemsList = this.getVacancyItems();
        return (
            <>
                {itemsList.length > 0 ? (
                    <div className="content-layout">
                        <div className="vacancy-list">
                            <div className="row">{itemsList.slice(0, itemsLimit)}</div>
                            {itemsList.length > itemsLimit &&
                            <div className="vacancy-list__more">
                                <a className="button button_bordered" href="/" onClick={(e) => this.nextPage(e)}>Загрузить еще</a>
                            </div>}
                        </div>
                    </div>
                ) : (<VacancyEmpty />)}
            </>
        );
    }
}

export class VacancyEmpty extends React.Component {
    shouldComponentUpdate() {
        return false;
    }
    
    render() {
        return (
            <div className="vacancy-empty">
                <div className="content-layout">
                    <div className="vacancy-empty__title h1">Похоже, в&nbsp;вашем городе все разобрали :(</div>
                    <div className="vacancy-empty__text">Заполните форму&nbsp;&mdash; и&nbsp;тогда точно не&nbsp;пропустите свежие и&nbsp;горячие вакансии</div>
                    <a className="button button_bordered" href="/" data-toggle_popup="profile" data-kind="other">Заполнить анкету</a>
                </div>
            </div>
        );
    }
}

export default VacancyPage;
