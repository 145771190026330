import React from 'react';
import {Loading} from '../../App';
import GlideSlider from '../GlideSlider';

class AboutPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.extra.onPageChange('about');
        this.needUpdate = true;
    }
    
    shouldComponentUpdate() {
        return this.needUpdate;
    }
    
    render() {
        if (!this.props.extra.content) return <Loading inline={true} />;
        this.needUpdate = false;
        const contentWorth = this.props.extra.content.blocks.our_values,
            contentStory = this.props.extra.content.blocks.our_life.blocks,
            contentHistory = this.props.extra.content.blocks.history;
        contentHistory.blocks = contentHistory.blocks.filter(item => item.title);
        return (
            <section className="about">
                <AboutWorth title={contentWorth.title}
                            text={contentWorth.short_description}
                            items={contentWorth.blocks} />
                
                {contentStory.length > 0 && <AboutStory storyItems={contentStory} />}
                
                {contentHistory.blocks.length > 0 &&
                <AboutHistory title={contentHistory.title}
                              text={contentHistory.short_description}
                              sliderItems={contentHistory.blocks} />}
            </section>
        );
    }
}

const worthColors = [
    {background: '#ffc000'},
    {background: '#fd8d8c'},
    {background: '#66aaa9'},
    {background: '#b6cd73'}
],
AboutWorth = props => {
    return (
        <div className="about-worth">
            <div className="content-layout">
                <div className="about-worth__title h1">{props.title}</div>
                <div className="about-worth__text">{props.text}</div>
                <div className="row">
                    {props.items.map((item, i) => (
                        <div className="col-12 col-sm-6 col-xl-3" key={item.title}>
                            <div className="about-worth-item">
                                <div className="about-worth-item__icon circle-icon" style={worthColors[i]}>
                                    <img src={item.image} alt="" />
                                </div>
                                <div className="about-worth-item__title">{item.title}</div>
                                <div className="about-worth-item__text">{item.short_description}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

class AboutStory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStory: 0
        };
        this.glideSlider = null;
        this.getGlideSlider = this.getGlideSlider.bind(this);
    }
    
    componentWillUnmount() {
        this.removeEvents();
    }
    
    storyChange(direction) {
        const storyCount = this.props.storyItems.length;
        let {currentStory} = this.state;
        if (direction === 'next') {
            currentStory++;
        } else if (direction === 'prev') {
            currentStory--;
        } else {
            currentStory = direction;
        }
        if (currentStory < 0) currentStory = storyCount - 1;
        if (currentStory >= storyCount) currentStory = 0;
        this.glideSlider.go(`=${currentStory}`);
        document.querySelectorAll('.about-item').forEach(el => {
            el.setAttribute('data-active', 'false');
            if (parseInt(el.getAttribute('data-index'), 10) === currentStory) el.setAttribute('data-active', 'true');
        });
        this.setState({
            currentStory: currentStory
        });
    }
    
    getGlideSlider(slider) {
        this.glideSlider = slider;
        this.addEvents();
    }
    
    addEvents() {
        this.eventListenerClick = (e) => {
            const elItem = e.target.closest('.about-item');
            if (!elItem) return;
            this.storyChange(parseInt(elItem.getAttribute('data-index'), 10));
        };
        window.addEventListener('click', this.eventListenerClick);
    }
    
    removeEvents() {
        window.removeEventListener('click', this.eventListenerClick);
    }
    
    render() {
        const {currentStory} = this.state;
        return (
            <>
                <div className="about__row">
                    <div className="about__body">
                        <div className="about__info">
                            <div className="about__title h1">{this.props.storyItems[currentStory].title}</div>
                            <div className="about__subtitle">{this.props.storyItems[currentStory].short_description}</div>
                            <div className="about__text">{this.props.storyItems[currentStory].full_description}</div>
                            <a className="button button_bordered" href="/" data-open_popup="profile" data-kind="about">Заполнить анкету</a>
                        </div>
                        <div className="about__arrows">
                            {this.props.storyItems.length > 1 &&
                            <>
                                <div className="button-arrow button-arrow_light button-arrow_prev" onClick={() => this.storyChange('prev')} />
                                <div className="button-arrow button-arrow_light button-arrow_next" onClick={() => this.storyChange('next')} />
                            </>}
                        </div>
                    </div>
                    <div className="about__album" style={{backgroundImage: `url(${this.props.storyItems[currentStory].image})`}}>
                        <img src={this.props.storyItems[currentStory].image} alt="" />
                        {this.props.storyItems.length > 1 &&
                        <>
                            <div className="button-arrow button-arrow_prev" onClick={() => this.storyChange('prev')} />
                            <div className="button-arrow button-arrow_next" onClick={() => this.storyChange('next')} />
                        </>}
                    </div>
                </div>
    
                {this.props.storyItems.length > 1 &&
                <GlideSlider className="about" getSlider={this.getGlideSlider}>
                    <div className="glide__track" data-glide-el="track">
                        <div className="glide__slides">
                            {this.props.storyItems.map((item, i) => (
                                <div className="glide__slide" key={i}>
                                    <div className="about-item" data-index={i} data-active={i === 0}>
                                        <div className="about-item__photo" style={{backgroundImage: `url(${item.image})`}}>
                                            <div />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                    <div className="glide__bullets" data-glide-el="controls[nav]">
                        {this.props.storyItems.map((item, i) => <div className="glide__bullet" data-glide-dir={`=${i}`} key={i} />)}
                    </div>
                </GlideSlider>}
            </>
        );
    }
}

const historyColors = [
    {color: '#fd8d8c'},
    {color: '#ffc000'},
    {color: '#b6cd73'},
    {color: '#c25181'},
    {color: '#fd8d8c'},
    {color: '#ffc000'},
    {color: '#b6cd73'},
    {color: '#c25181'},
    {color: '#fd8d8c'},
    {color: '#ffc000'},
    {color: '#b6cd73'},
    {color: '#c25181'}
],
AboutHistory = props => {
    return (
        <div className="about-story">
            <div className="content-layout">
                <div className="about-story__title h1">{props.title}</div>
                <div className="about-story__text">{props.text}</div>
                <GlideSlider className="history">
                    <div className="glide__arrows" data-glide-el="controls">
                        <div className="glide__arrow glide__arrow--left" data-glide-dir="<" />
                        <div className="glide__arrow glide__arrow--right" data-glide-dir=">" />
                    </div>
                    
                    <div className="glide__track" data-glide-el="track">
                        <div className="glide__slides">
                            {props.sliderItems.map((item, i) => (
                                <div className="glide__slide" key={i}>
                                    <div className="about-story-item">
                                        <div className="about-story-item__photo" style={{backgroundImage: `url(${item.image})`}} />
                                        <div className="about-story-item__point" style={historyColors[i]} />
                                        <div className="about-story-item__title">{item.title}</div>
                                        <div className="about-story-item__text">{item.short_description}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
    
                    {props.sliderItems.length > 1 &&
                    <div className="glide__bullets" data-glide-el="controls[nav]">
                        {props.sliderItems.map((item, i) => <div className="glide__bullet" data-glide-dir={`=${i}`} key={i} />)}
                    </div>}
                </GlideSlider>
            </div>
            <svg className="about-story__line" xmlns="http://www.w3.org/2000/svg" width="1920" height="1086.78" viewBox="0 0 1920 1086.78">
                <path d="M-2869.66,4133.76c700.47,269.28,1787.18-7.33,2157.854-770.11C-384.835,2690.8-733.562,3817.89,468,3824c476.477,2.42,763.33,3.93,1201,0,737.61-6.63,895.71-752.66,946-584" transform="translate(-278 -3149)" />
            </svg>
        </div>
    );
};

export default AboutPage;
