export const animate = (draw, duration) => {
    const start = performance.now();
    return requestAnimationFrame(function animate(time) {
        let timeFraction = (time - start) / duration;
        if (timeFraction > 1) timeFraction = 1;
        if (timeFraction > 0) draw(timeFraction);
        if (timeFraction < 1) requestAnimationFrame(animate);
    });
};

export const animationTimings = {
    quad: function (progress) {
        return Math.pow(progress, 2);
    },
    circ: function (progress) {
        return 1 - Math.sin(Math.acos(progress));
    },
    makeEaseOut: function (timing) {
        return function (progress) {
            return 1 - timing(1 - progress);
        };
    },
    makeEaseInOut: function (timing) {
        return function (progress) {
            if (progress < .5) {
                return timing(2 * progress) / 2;
            } else {
                return (2 - timing(2 * (1 - progress))) / 2;
            }
        };
    }
};
