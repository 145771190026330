import React from 'react';
import {Link} from 'react-router-dom';

class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.props.extra.onPageChange('notFound');
    }
    
    shouldComponentUpdate() {
        return false;
    }
    
    render() {
        return (
            <section className="not-found">
                <div className="content-layout">
                    <div className="not-found__title">404</div>
                    <div className="not-found__link">
                        <Link to="/">Вернуться на главную</Link>
                    </div>
                </div>
            </section>
        );
    }
}

export default NotFound;
