import React from 'react';
import {Link} from 'react-router-dom';
import SocialLinks from './SocialLinks';
import Logo from '../assets/img/logo_wow.svg';

const currentYear = new Date().getFullYear();

class Footer extends React.Component {
    shouldComponentUpdate() {
        return false;
    }
    
    render() {
        return (
            <footer className="footer">
                <div className="content-layout">
                    <div className="footer__row">
                        <div className="footer__col">
                            <div className="footer-menu">
                                <div className="footer-menu__title">Своя компания</div>
                                <div className="footer-menu__item">
                                    <Link to="/about">О компании</Link>
                                </div>
                                <div className="footer-menu__item">
                                    <Link to="/corporate_culture">Корпоративная культура</Link>
                                </div>
                                <div className="footer-menu__item">
                                    <Link to="/advantages">Преимущества</Link>
                                </div>
                            </div>
                        </div>
                        <div className="footer__col">
                            <div className="footer-menu">
                                <div className="footer-menu__title">Вакансии</div>
                                <div className="footer-menu__item">
                                    <Link to="/vacancy">В ресторане</Link>
                                </div>
                                <div className="footer-menu__item">
                                    <Link to={{pathname: '/vacancy', search: '?office'}}>В офисе</Link>
                                </div>
                            </div>
                        </div>
                        <div className="footer__col">
                            <div className="footer-menu">
                                <div className="footer-menu__title">Контакты</div>
                                <div className="footer-menu__item">
                                    <a href="https://svoya-kompaniya.ru" target="_blank" rel="noopener noreferrer">Основной сайт</a>
                                </div>
                                <div className="footer-menu__item">
                                    <Link to="/contact">По поводу сотрудничества</Link>
                                </div>
                            </div>
                        </div>
                        <div className="footer__col">
                            <SocialLinks className="footer__social" links={this.props.socialLinks} />
                        </div>
                    </div>
                    <div className="footer__row">
                        <div className="footer__copyright">&copy; Своя компания, 2006−{currentYear} Все права
                            защищены.
                        </div>
                        <div className="footer__dev">
                            Дизайн и разработка —
                            <a href="http://wow.wearewowagency.com/" target="_blank" rel="noopener noreferrer">
                                <img src={Logo} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
