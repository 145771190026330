import React from 'react';
import GlideSlider from './GlideSlider';
import Tag from '../assets/img/tag_footer.svg';

class FooterSlider extends React.Component {
    constructor(props) {
        super(props);
        this.sliderContent = {};
    }
    
    shouldComponentUpdate(nextProps) {
        if (!nextProps.content || !nextProps.currentPage) return false;
        return !(this.props.content && this.props.currentPage === nextProps.currentPage);
    }
    
    getSliderContent() {
        if (!this.props.content || !this.props.currentPage) return null;
        if (this.sliderContent[this.props.currentPage]) return this.sliderContent[this.props.currentPage];
        const images = this.props.content.blocks.feedback.images;
        let counter = 0,
            slideList = [],
            bulletList = [];
        for (let key in images) {
            if (!images.hasOwnProperty(key)) continue;
            if (!images[key]) continue;
            if (!images[key].length) continue;
            slideList.push(
                <div className="glide__slide" style={{backgroundImage: `url(${images[key]})`}} key={images[key]}>
                    <div />
                </div>
            );
            bulletList.push(<div className="glide__bullet" data-glide-dir={`=${counter}`} key={images[key]} />);
            counter++;
        }
        this.sliderContent[this.props.currentPage] = {};
        this.sliderContent[this.props.currentPage].slideList = slideList;
        this.sliderContent[this.props.currentPage].bulletList = bulletList;
        return this.sliderContent[this.props.currentPage];
    }
    
    render() {
        const sliderContent = this.getSliderContent();
        return (
            <section className="footer-slider">
                {this.props.content && <Slider sliderContent={sliderContent} />}
                <div className="footer-slider__tag">
                    <img src={Tag} alt="" />
                </div>
            </section>
        );
    }
}

const Slider = props => {
    if (!props.sliderContent) return <></>;
    if (!props.sliderContent.slideList.length) return <></>;
    return (
        <GlideSlider className="footer">
            <div className="glide__arrows" data-glide-el="controls">
                <div className="glide__arrow glide__arrow--left" data-glide-dir="<" />
                <div className="glide__arrow glide__arrow--right" data-glide-dir=">" />
            </div>
            
            <div className="glide__track" data-glide-el="track">
                <div className="glide__slides">
                    {props.sliderContent.slideList}
                </div>
            </div>
            
            <div className="glide__bullets" data-glide-el="controls[nav]">
                {props.sliderContent.bulletList}
            </div>
        </GlideSlider>
    );
};

export default FooterSlider;
