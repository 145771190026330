import React from 'react';
import {Link} from 'react-router-dom';
import {Loading} from '../../App';
import ActionMainPage from '../../helpers/ActionMainPage';
import {FormCompleted, FormInviteShort} from '../FormInvite';
import GlideSlider from '../GlideSlider';
import {AdvantagesFactsSlider} from './AdvantagesPage';
import {VacancyEmpty} from './VacancyPage';

const pointStyles = [
        {color: '#fda516'},
        {color: '#b6cd73'},
        {color: '#66aaa9'},
        {color: '#ffc000'},
        {color: '#ffc000'}
    ];

class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.extra.onPageChange('main');
        this.isActionInit = false;
        this.initTimeout = null;
        this.initTimeoutDuration = 1000;
        if (this.props.extra.content) {
            this.initTimeout = setTimeout(() => {
                ActionMainPage.init();
                ActionMainPage.checkResize();
            }, this.initTimeoutDuration);
            this.isActionInit = true;
        }
    }
    
    componentDidUpdate() {
        if (!this.props.extra.content) return;
        if (this.isActionInit) return;
        this.initTimeout = setTimeout(() => {
            ActionMainPage.init();
            ActionMainPage.checkResize();
        }, this.initTimeoutDuration);
        this.isActionInit = true;
    }
    
    componentWillUnmount() {
        clearTimeout(this.initTimeout);
        ActionMainPage.destroy();
        ActionMainPage.el_main = null;
    }
    
    render() {
        if (!this.props.extra.content) return <Loading inline={true} />;
        const contentAbout = this.props.extra.content.blocks.about_company,
            contentAdvantages = this.props.extra.content.blocks.advantages,
            contentCulture = this.props.extra.content.blocks.corporate_culture,
            contentFeedback = this.props.extra.content.blocks.feedback,
            currentCityDeclension = this.props.extra.currentCityDeclension,
            localCity = this.props.extra.localCity,
            vacancy = this.props.extra.vacancy,
            inviteFormData = this.props.extra.inviteFormData,
            onFormSync = this.props.extra.onFormSync,
            onFormSubmit = this.props.extra.onFormSubmit;
        return (
            <section className="main">
                <MainAbout title={contentAbout.title}
                           subtitle={contentAbout.short_description}
                           textLeft={contentAbout.full_description1}
                           textRight={contentAbout.full_description2}
                           img={contentAbout.image} />
                
                <MainAdvantages title={contentAdvantages.title}
                                text={contentAdvantages.short_description}
                                sliderFacts={contentAdvantages.blocks} />
                
                <MainVacancy city={localCity}
                             cityDeclension={currentCityDeclension}
                             vacancy={vacancy} />
                
                <MainCulture title={contentCulture.title}
                             text={contentCulture.full_description}
                             fact={contentCulture.short_description}
                             img={contentCulture.image} />
                
                <MainForm title={contentFeedback.title}
                          text={contentFeedback.short_description}
                          vacancy={vacancy}
                          inviteFormData={inviteFormData}
                          onSync={onFormSync}
                          onSubmit={onFormSubmit} />
                
                <MainProgress />
            </section>
        );
    }
}

class MainLine extends React.Component {
    constructor(props) {
        super(props);
        this.lineColors = [
            ['#a1afb1', '#ffc000', '#fda516'],
            ['#b4b7bb', '#fda516', '#b6cd73'],
            ['#b4b7bb', '#b6cd73', '#66aaa9'],
            ['#7896a0', '#66aaa9', '#ffc000'],
            ['#b4b7bb', '#ffc000']
        ];
    }
    
    shouldComponentUpdate() {
        return false;
    }
    
    parseChildren() {
        let children = [];
        for (let i = 0; i < this.lineColors[this.props.index].length; i++) {
            children.push(
                <div className={`main-line__wrap main-line__wrap_${(!i) ? 'bg' : i}`} key={i}>
                    {React.cloneElement(this.props.children, {}, React.cloneElement(this.props.children.props.children, {stroke: this.lineColors[this.props.index][i]}))}
                </div>
            );
        }
        return children;
    }
    
    render() {
        const children = this.parseChildren();
        return <div className={this.props.className}>{children}</div>;
    }
}

const MainAbout = props => {
    return (
        <>
            <MainLine className="main-line main-line_1" index={0}>
                <svg xmlns="http://www.w3.org/2000/svg" width="744.52" height="1652.62" viewBox="0 0 744.52 1652.62" preserveAspectRatio="none">
                    <path d="M0,.5c295.49,14.67,743.83,407.33,744,707,.27,453.07-387.54,379.23-247,945" fill="none" strokeDasharray="20 8" vectorEffect="non-scaling-stroke" />
                </svg>
            </MainLine>
            
            <div className="main-about">
                <div className="content-layout">
                    <div className="main-about__wrap">
                        <div className="main-about__row">
                            <div className="main-about__body">
                                <div className="main__title h1">
                                    <Link to="/about">{props.title}</Link>
                                </div>
                                <div className="main-about__subtitle">{props.subtitle}</div>
                                <div className="main__text" dangerouslySetInnerHTML={{__html: props.textLeft}} />
                            </div>
                            <div className="main-about__number">
                                <div className="main__number main__number_1" style={pointStyles[0]}>
                                    <div className="main__number-point" />
                                    <div className="main__number-text">01</div>
                                </div>
                                <div className="main__text" dangerouslySetInnerHTML={{__html: props.textRight}} />
                                <Link className="main-about__link button button_bordered" to="/about">О нас</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <img className="main-about__img" src={props.img} alt="" />
            </div>
        </>
    );
};

const MainAdvantages = props => {
    return (
        <div className="main-advantages">
            <div className="main-advantages__wrap">
                <MainLine className="main-line main-line_2" index={1}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30.91" height="344.2" viewBox="0 0 30.91 344.2" preserveAspectRatio="none">
                        <path d="M30.43.1C5.51,119.92-3.3,234.67,2,344.17" fill="none" stroke="#b4b7bb" strokeDasharray="20 8" fillRule="evenodd" vectorEffect="non-scaling-stroke" />
                    </svg>
                </MainLine>
                
                <div className="content-layout">
                    <div className="main-advantages__row">
                        <div className="main-advantages__number">
                            <div className="main__number main__number_2" style={pointStyles[1]}>
                                <div className="main__number-point" />
                                <div className="main__number-text">02</div>
                            </div>
                        </div>
                        <div className="main-advantages__body">
                            <div className="main__title h1">
                                <Link to="/advantages">{props.title}</Link>
                            </div>
                            <div className="main__text">{props.text}</div>
                        </div>
                    </div>
                </div>
            </div>
            
            {props.sliderFacts.length > 0 && <AdvantagesFactsSlider facts={props.sliderFacts} />}
        </div>
    );
};

class MainVacancy extends React.Component {
    constructor(props) {
        super(props);
        this.updateTimeout = null;
        this.updateTimeoutDuration = 500;
    }
    
    componentDidUpdate() {
        clearTimeout(this.updateTimeout);
        this.updateTimeout = setTimeout(() => {
            if (ActionMainPage.el_main) ActionMainPage.checkResize();
        }, this.updateTimeoutDuration);
    }
    
    render() {
        const vacancy = this.props.vacancy;
        return (
            <div className="main-vacancy">
                <MainLine className="main-line main-line_3" index={2}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="961.68" height="1052.21" viewBox="0 0 961.68 1052.21" preserveAspectRatio="none">
                        <path d="M.29.4C111,80.88,230.87,151.63,355.69,212.3c366,177.89,600.33,535.85,605.49,839.9" fill="none" strokeDasharray="20 8" fillRule="evenodd" vectorEffect="non-scaling-stroke" />
                    </svg>
                </MainLine>
                
                <div className="content-layout">
                    <div className="main-vacancy__row">
                        <div className="main-vacancy__number">
                            <div className="main__number main__number_3" style={pointStyles[2]}>
                                <div className="main__number-point" />
                                <div className="main__number-text">03</div>
                            </div>
                        </div>
                        <div className="main-vacancy__body">
                            <div className="main__title h1">
                                <Link to="/vacancy">Вакансии в</Link>
                                {' '}
                                <a href="/" data-open_popup="city-select-local">{this.props.cityDeclension}</a>
                            </div>
                            <div className="main__text">Искреннее отношение к&nbsp;гостям и&nbsp;друг другу&nbsp;&mdash; то, что отличает нас от&nbsp;других. Присоединяйтесь!</div>
                        </div>
                    </div>
                </div>
                
                {!vacancy ? (<Loading inline={true} />) : (
                    vacancy.list.length > 0 ? (
                        <MainVacancySlider vacancy={vacancy} city={this.props.city} />
                    ) : (
                        <VacancyEmpty />)
                )}
            </div>
        );
    }
}

class MainVacancySlider extends React.Component {
    shouldComponentUpdate(nextProps) {
        return this.props.city.en !== nextProps.city.en;
    }
    
    render() {
        return (
            <>
                <GlideSlider className="vacancy" doUpdate={true}>
                    <div className="glide__track" data-glide-el="track">
                        <div className="glide__slides">
                            {this.props.vacancy.category.list.map((item, i) => (
                                <div className="glide__slide" key={i}>
                                    <div className="vacancy-item">
                                        <Link className="vacancy-item__link" to={{
                                            pathname: '/vacancy',
                                            search: `?category=${item.id}`
                                        }} />
                                        {item.photo &&
                                        <div className="vacancy-item-photo" style={{color: item.icon_color}}>
                                            <img className="vacancy-item-photo__img" src={item.photo} alt="" />
                                            <div className="vacancy-item-photo__icon circle-icon">
                                                <img src={item.icon} alt="" />
                                            </div>
                                        </div>}
                                        <div className="label">{this.props.city.ru}</div>
                                        <div className="vacancy-item__title">{item.title}</div>
                                        <div className="vacancy-item__text" dangerouslySetInnerHTML={{__html: item.description}} />
                                    </div>
                                </div>
                            ))}
                            <div />
                        </div>
                    </div>
                    
                    <div className="glide__bullets" data-glide-el="controls[nav]">
                        {this.props.vacancy.category.list.map((item, i) => <div className="glide__bullet" data-glide-dir={`=${i}`} key={i} />)}
                    </div>
                </GlideSlider>
                
                <div className="main-vacancy__profile">
                    <a className="button button_bordered" href="/" data-toggle_popup="profile" data-kind="other">Заполнить анкету</a>
                </div>
            </>
        );
    }
}

class MainForm extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmitInviteForm = this.handleSubmitInviteForm.bind(this);
    }
    
    handleSubmitInviteForm() {
        if (ActionMainPage.el_main) ActionMainPage.complete();
        this.props.onSubmit();
    }
    
    render() {
        const {vacancy, inviteFormData, onSync} = this.props;
        return (
            <div className="main-form" data-active={inviteFormData.isComplete}>
                <MainLine className="main-line main-line_5" index={4}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="83.86" height="298.5" viewBox="0 0 83.86 298.5" preserveAspectRatio="none">
                        <path d="M.33.38C111.89,97.49,96.4,231.53,39.22,298.18" fill="none" strokeDasharray="20 8" fillRule="evenodd" vectorEffect="non-scaling-stroke" />
                    </svg>
                </MainLine>
                
                <div className="content-layout">
                    <div className="main-form__wrap">
                        <div className="main-form__body">
                            <div className="main__number main__number_5" style={pointStyles[4]}>
                                <div className="main__number-text">05</div>
                            </div>
                            {inviteFormData.isComplete ? (<FormCompleted />) : (
                                <>
                                    <div className="main__title h1">{this.props.title}</div>
                                    <div className="main__text">{this.props.text}</div>
                                </>)}
                        </div>
                    </div>
                    <FormInviteShort className="main-form"
                                     vacancy={vacancy}
                                     formData={inviteFormData}
                                     onSync={onSync}
                                     onSubmit={this.handleSubmitInviteForm} />
                </div>
            </div>
        );
    }
}

const MainCulture = props => {
    return (
        <div className="main-culture">
            <div className="main-culture__wrap">
                <MainLine className="main-line main-line_4" index={3}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="404.56" height="175.28" viewBox="0 0 404.56 175.28" preserveAspectRatio="none">
                        <path d="M404.2.34c-85.59,90.19-218,154-404.15,174.45" fill="none" strokeDasharray="20 8" fillRule="evenodd" vectorEffect="non-scaling-stroke" />
                    </svg>
                </MainLine>
                
                <div className="content-layout">
                    <div className="main__number main__number_4" style={pointStyles[3]}>
                        <div className="main__number-point" />
                        <div className="main__number-text">04</div>
                    </div>
                    <div className="main-culture__body">
                        <div className="main__title h1">
                            <Link to="/corporate_culture">{props.title}</Link>
                        </div>
                        <div className="main__text" dangerouslySetInnerHTML={{__html: props.text}} />
                        <div className="main-culture__fact">{props.fact}</div>
                    </div>
                </div>
            </div>
            <img className="main-culture__img" src={props.img} alt="" />
        </div>
    );
};

const MainProgress = () => {
    return (
        <>
            <div className="main-progress__marker" />
            <div className="main-progress">
                <div className="main-progress__bar" />
                <div className="main-progress__bar main-progress__bar_clone">
                    <div className="main-progress__row">
                        <div className="main-progress__percent">1%</div>
                        <div className="main-progress__text">работы мечты загружается</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainPage;
