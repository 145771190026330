import React from 'react';
import {Link} from 'react-router-dom';
import Tag from '../assets/img/tag_header.svg';

const partNames = {
    about: 'О компании',
    culture: 'Корпоративная культура',
    advantages: 'Преимущества',
    contacts: 'Контакты',
    vacancy: 'Вакансии'
};

const Cover = ({extra}) => {
    if (!extra.content) return <></>;
    const content = extra.content.blocks.banner,
        description = content.full_description || content.short_description,
        part = extra.currentPage;
    if (part === 'main') {
        return <MainCover title={content.title}
                          subtitle={content.short_description}
                          text={description}
                          bg={content.image} />;
    }
    return <HeaderCover title={content.title}
                        text={description}
                        bg={content.image}
                        part={part}
                        cityRu={extra.localCity.ru} />;
};

class MainCover extends React.Component {
    shouldComponentUpdate() {
        return false;
    }
    
    render() {
        return (
            <section className="main-cover">
                <div className="main-cover__tag">
                    <img src={Tag} alt="" />
                </div>
                <div className="main-cover__wrap">
                    <div className="content-layout">
                        <div className="main-cover__title">{this.props.title}</div>
                        <div className="main-cover__subtitle">{this.props.subtitle}</div>
                        <div className="main-cover__text" dangerouslySetInnerHTML={{__html: this.props.text}} />
                        <div className="main-cover__start" />
                    </div>
                </div>
                <div className="main-cover__bg" style={{backgroundImage: `url(${this.props.bg})`}} />
            </section>
        );
    }
}

class HeaderCover extends React.Component {
    shouldComponentUpdate(nextProps) {
        if (this.props.part !== nextProps.part) return true;
        return this.props.cityRu !== nextProps.cityRu;
    }
    
    render() {
        return (
            <section className={`header-cover header-cover_${this.props.part}`} style={{backgroundImage: `url(${this.props.bg})`}}>
                <div className="content-layout">
                    <div className="header-cover__nav">
                        <Link to="/">Главная</Link> I <span>{partNames[this.props.part]}</span>
                    </div>
                    <div className="header-cover__title">
                        {this.props.title}
                        {this.props.part === 'contacts' &&
                        <>
                            <br />
                            <a href="/" data-open_popup="city-select-local">{this.props.cityRu}</a>
                        </>
                        }
                    </div>
                    {this.props.text &&
                    <div className="header-cover__text" dangerouslySetInnerHTML={{__html: this.props.text}} />}
                </div>
            </section>
        );
    }
}

export default Cover;
