import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import ScrollToTop from './helpers/ScrollToTop';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/styles/app.scss';

ReactDOM.render((
    <Router>
        <ScrollToTop>
            <App />
        </ScrollToTop>
    </Router>), document.getElementById('root'));
serviceWorker.unregister();
