import {animate, animationTimings} from './Animate';

const ActionMainPage = {
    init: function () {
        this.el_main = document.querySelector('.main');
        this.el_main_lines = document.querySelectorAll('.main-line');
        this.el_main_progress_bars = document.querySelectorAll('.main-progress__bar');
        this.el_main_progress_percent = document.querySelector('.main-progress__percent');
        this.el_main_progress_marker = document.querySelector('.main-progress__marker');
        this.el_main_cover_start = document.querySelector('.main-cover__start');
        this.el_main_numbers = document.querySelectorAll('.main__number');
        this.el_main_number_points = document.querySelectorAll('.main__number-point');
        this.el_main_about_wrap = document.querySelector('.main-about__wrap');
        this.el_main_form = document.querySelector('.main-form');
        this.el_main_form_btn = this.el_main_form.querySelector('.main-form__btn');
        this.el_main_form_input_texts = this.el_main_form.querySelectorAll('input[type="text"]');
        this.el_main_form_input_select = this.el_main_form.querySelector('.input-select');
        this.el_form_completed = this.el_main_form.querySelector('.form-completed');
        this.progress_marker_offset = null;
        this.scroll_pos = {top: 0, center: 0, bottom: 0};
        this.scroll_percent = 0;
        this.progress_bar_min_width = this.el_main_progress_bars[0].offsetWidth;
        this.number_points_collect = [];
        this.lines_collect = [];
        this.animation_timing = animationTimings.makeEaseInOut(animationTimings.quad);
        this.scroll_duration = 1000;
        this.complete_scroll = false;
        this.complete_total = false;
        this.resize_timeout = null;
        this.resize_timeout_duration = 300;
        this.window_width = window.innerWidth;
        this.addEvents();
    },
    destroy: function () {
        window.removeEventListener('scroll', this.eventListenerScroll);
        window.removeEventListener('resize', this.eventListenerResize);
        clearTimeout(this.resize_timeout);
    },
    addEvents: function () {
        this.eventListenerScroll = () => this.checkScroll();
        this.eventListenerResize = () => {
            clearTimeout(this.resize_timeout);
            this.resize_timeout = setTimeout(() => this.checkResize(), this.resize_timeout_duration);
        };
        window.addEventListener('scroll', this.eventListenerScroll);
        window.addEventListener('resize', this.eventListenerResize);
        this.el_main_cover_start.addEventListener('click', () => this.scrollToStep1());
        if (this.el_main_form_input_select) this.el_main_form_input_select.addEventListener('click', () => this.scrollToStep5());
        this.el_main_form_btn.addEventListener('click', () => this.scrollToStep5());
        this.el_main_form_input_texts.forEach((el) => {
            el.addEventListener('focus', () => this.scrollToStep5());
            el.addEventListener('keyup', () => this.checkInput());
            el.addEventListener('change', () => this.checkInput());
        });
    },
    collectLines: function () {
        if (window.innerWidth < 992) return;
        let lines_collect = [];
        this.lines_collect = [];
        this.el_main_lines.forEach((el, i) => {
            let top = el.getBoundingClientRect().top + window.pageYOffset,
                bottom = 0,
                left = 0,
                height = 0,
                height_top = 0,
                height_bottom = 0,
                width = null,
                point_center = 0,
                el_point = this.el_main_numbers[i].querySelector('.main__number-point');
            if (el_point) point_center = this.el_main_numbers[i].getBoundingClientRect().top + window.pageYOffset + el_point.offsetTop + el_point.offsetHeight / 2;
            if (i === 0) top = this.el_main_cover_start.getBoundingClientRect().top + window.pageYOffset + this.el_main_cover_start.offsetHeight / 2;
            height_top = point_center - top;
            height = el.offsetHeight;
            height_bottom = height - height_top;
            bottom = top + height;
            if (i === 0) {
                height = height_top * 3;
                height_bottom = height - height_top;
                bottom = top + height;
                left = this.el_main_cover_start.getBoundingClientRect().left + this.el_main_cover_start.offsetHeight / 2;
                width = this.el_main_numbers[i].getBoundingClientRect().left + el_point.offsetLeft - left + 50;
                if (window.innerWidth < 1300) width -= 10;
                el.style.top = top + 'px';
                el.style.left = left + 'px';
                el.style.height = height + 'px';
            }
            if (i === 1) {
                left = this.el_main_numbers[i].getBoundingClientRect().left + el_point.offsetLeft + 5;
                el.style.left = left + 'px';
            }
            if (i === 2) {
                left = this.el_main_numbers[i].getBoundingClientRect().left + el_point.offsetLeft - 335;
                el.style.left = left + 'px';
            }
            if (i === 3) {
                let point_right = window.innerWidth - (this.el_main_numbers[i].getBoundingClientRect().left + el_point.offsetLeft + el_point.offsetHeight / 2),
                    delta = 1.23;
                if (window.innerWidth > 1800) delta += 0.13;
                el.style.top = '0px';
                el.style.top = point_center - (el.getBoundingClientRect().top + window.pageYOffset) - point_right / delta + 'px';
                top = el.getBoundingClientRect().top + window.pageYOffset;
                height = el.offsetHeight;
                height_top = point_center - top;
                width = height * 2.3;
                height_bottom = height - height_top;
                bottom = top + height;
                left = window.innerWidth - width;
                el.style.left = left + 'px';
            }
            if (i === 4) {
                height = this.el_main_form_btn.getBoundingClientRect().top + window.pageYOffset - top;
                height_top = height;
                height_bottom = height;
                bottom = top + height;
                el.style.left = this.el_main_form_btn.getBoundingClientRect().left + 35 + 'px';
                el.style.height = height + 'px';
            }
            el.querySelectorAll('svg').forEach((el) => {
                el.style.height = height + 'px';
                if (width !== null) el.style.width = width + 'px';
            });
            if (el_point) {
                el.querySelector('.main-line__wrap_2').style.top = height_top + 'px';
                el.querySelector('.main-line__wrap_2 svg').style.top = height_top * -1 + 'px';
            }
            lines_collect.push({
                index: i,
                top: top,
                bottom: bottom,
                height: height,
                height_top: height_top,
                height_bottom: height_bottom,
                point_center: point_center
            });
            if (el.getAttribute('data-active') || el.getAttribute('data-active') === '') return;
            this.lines_collect.push({
                index: i,
                top: top,
                bottom: bottom,
                height: height,
                height_top: height_top,
                height_bottom: height_bottom,
                point_center: point_center
            });
        });
        this.checkLines(lines_collect);
    },
    collectNumberPoints: function () {
        if (this.complete_scroll) return;
        this.number_points_collect = [];
        this.el_main_number_points.forEach((el, i) => {
            let el_parent = this.el_main_numbers[i];
            if (el_parent.getAttribute('data-active') || el_parent.getAttribute('data-active') === '') return;
            this.number_points_collect.push({
                index: i,
                offset: el_parent.getBoundingClientRect().top + window.pageYOffset + el.offsetTop,
                color: el_parent.style.color
            });
        });
    },
    scrollToStep1: function () {
        let pos = this.el_main_about_wrap.getBoundingClientRect().top + window.pageYOffset;
        if (window.innerHeight > this.el_main_about_wrap.offsetHeight) pos = pos + this.el_main_about_wrap.offsetHeight / 2 - window.innerHeight / 2;
        this.scrollTo(pos);
    },
    scrollToStep5: function () {
        if (this.complete_scroll) return;
        let pos = this.el_main_form.getBoundingClientRect().top + window.pageYOffset;
        if (window.innerHeight > this.el_main_form.offsetHeight) pos = pos + this.el_main_form.offsetHeight / 2 - window.innerHeight / 2;
        this.scrollTo(pos);
    },
    scrollTo: function (pos, dur) {
        let cur_pos = window.pageYOffset,
            distance = pos - window.pageYOffset,
            duration = dur || this.scroll_duration,
            velocity = Math.abs(distance) * duration / window.innerHeight;
        if (velocity > duration) velocity = duration;
        duration = velocity;
        animate((progress) => {
            let quad_progress = this.animation_timing(progress);
            window.scrollTo(0, cur_pos + distance * quad_progress);
        }, duration);
    },
    checkResize: function () {
        this.collectLines();
        if (this.complete_total) return;
        if (this.complete_scroll) return this.setProgressBarWidth();
        if (this.window_width !== window.innerWidth) {
            this.window_width = window.innerWidth;
            this.scroll_pos.top = 0;
        }
        this.progress_marker_offset = this.el_main_progress_marker.getBoundingClientRect().top + window.pageYOffset + this.el_main_progress_marker.offsetHeight;
        this.collectNumberPoints();
        this.checkScroll();
    },
    checkScroll: function () {
        if (this.complete_total) return;
        if (!this.progress_marker_offset) return;
        if (this.complete_scroll) return;
        if (this.scroll_pos.top > window.pageYOffset) return;
        this.scroll_pos.top = window.pageYOffset;
        this.scroll_pos.center = window.pageYOffset + window.innerHeight / 2;
        this.scroll_pos.bottom = window.pageYOffset + window.innerHeight;
        this.scroll_percent = this.scroll_pos.top * 100 / (this.progress_marker_offset - window.innerHeight + this.progress_marker_offset / 10);
        if (this.scroll_percent < 1) this.scroll_percent = 1;
        if (this.scroll_percent >= 90) this.scroll_percent = 90;
        if (this.scroll_pos.bottom >= this.progress_marker_offset) {
            this.complete_scroll = true;
            this.scroll_percent = 90;
            this.el_main.setAttribute('data-complete', '');
            this.el_main_numbers[4].setAttribute('data-active', '');
            this.el_main_lines[4].setAttribute('data-active', '');
            this.checkInput();
        }
        this.setProgressBarWidth();
        this.checkNumberPoints();
        this.checkLines();
    },
    setProgressBarWidth: function () {
        if (this.complete_total) return;
        let progress_bar_width = 0,
            width_delta = 0;
        if (window.innerWidth < 992) {
            progress_bar_width = window.innerWidth / 100 * this.scroll_percent + 'px';
        } else {
            width_delta = this.progress_bar_min_width - this.progress_bar_min_width / 100 * this.scroll_percent * 2;
            if (width_delta < 0) width_delta = 0;
            progress_bar_width = width_delta + (window.innerWidth - width_delta) / 100 * this.scroll_percent + 'px';
        }
        this.el_main_progress_bars.forEach((el) => el.style.width = progress_bar_width);
        this.el_main_progress_percent.innerHTML = Math.floor(this.scroll_percent) + '%';
    },
    checkLines: function (collect) {
        if (window.innerWidth < 992) return;
        collect = collect || this.lines_collect;
        if (!collect.length) return;
        let lines_collect = [];
        for (let i = 0; i < collect.length; i++) {
            let el = this.el_main_lines[collect[i].index],
                el_wrap_bg = el.querySelector('.main-line__wrap_bg'),
                el_wrap_1 = el.querySelector('.main-line__wrap_1'),
                el_wrap_2 = el.querySelector('.main-line__wrap_2');
            if (el.getAttribute('data-active') || el.getAttribute('data-active') === '' || this.scroll_pos.center >= collect[i].bottom) {
                el_wrap_bg.style.height = '0px';
                el_wrap_1.style.height = collect[i].height_top + 'px';
                if (el_wrap_2) el_wrap_2.style.height = collect[i].height_bottom + 'px';
                el.setAttribute('data-active', '');
                continue;
            }
            if (this.scroll_pos.center > collect[i].top) {
                let wrap_1_height = this.scroll_pos.center - collect[i].top,
                    wrap_2_height = 0,
                    wrap_bg_height = collect[i].height - wrap_1_height;
                if (wrap_1_height > collect[i].height_top) wrap_1_height = collect[i].height_top;
                if (wrap_bg_height < 0) wrap_bg_height = 0;
                el_wrap_bg.style.height = wrap_bg_height + 'px';
                el_wrap_1.style.height = wrap_1_height + 'px';
                if (el_wrap_2) {
                    if (this.scroll_pos.center > collect[i].point_center) wrap_2_height = this.scroll_pos.center - collect[i].point_center;
                    if (wrap_2_height > collect[i].height_bottom) wrap_2_height = collect[i].height_bottom;
                    el_wrap_2.style.height = wrap_2_height + 'px';
                }
            }
            lines_collect.push(collect[i]);
        }
        this.lines_collect = lines_collect;
    },
    checkNumberPoints: function () {
        if (this.complete_total) return;
        if (!this.number_points_collect.length) return;
        let number_points_collect = [];
        for (let i = 0; i < this.number_points_collect.length; i++) {
            if (this.scroll_pos.center >= this.number_points_collect[i].offset) {
                this.el_main_numbers[this.number_points_collect[i].index].setAttribute('data-active', '');
                this.el_main_progress_bars[0].style.background = this.number_points_collect[i].color;
                continue;
            }
            number_points_collect.push(this.number_points_collect[i]);
        }
        this.number_points_collect = number_points_collect;
    },
    checkInput: function () {
        if (!this.complete_scroll) return false;
        if (this.el_form_completed) return this.complete();
        let valid_counter = 0,
            valid_cost = 3;
        this.el_main_form_input_texts.forEach((el) => {
            if (!el.value.trim().length) return;
            if (el.name === 'phone' && el.value.length < 18) return;
            valid_counter++;
        });
        if (this.complete_total) return;
        this.scroll_percent = 93 + valid_cost * valid_counter;
        this.setProgressBarWidth();
    },
    complete: function () {
        this.complete_total = true;
        this.scroll_percent = 100;
        this.el_main_form.setAttribute('data-active', 'true');
        this.el_main_progress_bars.forEach((el) => el.style.width = '100%');
        this.el_main_progress_percent.innerHTML = '100%';
    }
};

export default ActionMainPage;
