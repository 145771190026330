export const SendMetrics = (currentPage, formType, initiatorPopupOpen) => {
    if (!window.ym) return;
    let goalName = 'OTPRAVIT';
    if (formType === 'complete') {
        if (initiatorPopupOpen === 'header') goalName = 'ZAPOLNIT';
        if (initiatorPopupOpen === 'vacancy') goalName = 'VNUTRI';
        if (initiatorPopupOpen === 'about') goalName = 'FILLIN';
    } else {
        goalName = 'BYSTRO';
        if (currentPage === 'vacancy') goalName = 'POSLAT';
        if (currentPage === 'main') goalName = 'VPISAT';
    }
    window.ym(55563700, 'reachGoal', goalName);
};
