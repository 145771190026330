import React from 'react';
import Cleave from 'cleave.js/react';
import {ReactComponent as IconCheck} from '../assets/img/icon_check.svg';
import AgreementPdf from '../assets/files/agreement_sc.pdf';

const errorTypes = {
    fio: 'Введите ваше ФИО:',
    phone: 'Введите корректный телефон:',
    age: 'Введите ваш возраст:',
    vacancy: 'Выберите вакансию:',
    address: 'Выберите адрес ресторана:',
    email: 'Введите корректный адрес эл. почты:',
    agree: 'Вы не дали согласие:'
};

const FormInvite = ({match, extra}) => {
    if (!extra.content || match.params.part === undefined) return <></>;
    if (extra.inviteFormData.isComplete) return <FormCompleted />;
    const feedback = extra.content ? extra.content.blocks.feedback : {};
    return (
        <section className="form-invite">
            <div className="content-layout">
                <div className="form-invite__title h1">{feedback.title}</div>
                <div className="form-invite__text">{feedback.short_description}</div>
                <FormInviteShort className="form-invite"
                                 vacancy={extra.vacancy}
                                 formData={extra.inviteFormData}
                                 onSync={extra.onSync}
                                 onSubmit={extra.onSubmit} />
            </div>
        </section>
    );
};

const InputSelect = props => {
    let listItems = [];
    if (props.isSelect) {
        if (props.name === 'vacancy') {
            if (props.value !== 'Вакансия' && props.value !== 'Любая вакансия') listItems.push('Любая вакансия');
        }
        if (props.name === 'address') {
            if (props.value !== 'Ресторан' && props.value !== 'Любой ресторан' && props.value !== 'Любой офис') listItems.push('Любой ресторан');
        }
        props.list.forEach(item => {
            if (item === props.value) return;
            listItems.push(item);
        });
    }
    return (
        <>
            {props.isError && <div className="input__error">{errorTypes[props.name]}</div>}
            {props.isSelect && props.value !== 'Любой офис' ? (
                <div className="input-select" name={props.name} onClick={(e) => props.onClick(e)}>
                    <div className="input-select__text">{props.value}</div>
                    <div className="input-select__list" data-custom_scroll="">
                        <div>
                            {listItems.map((item, i) =>
                                <div className="input-select__list-item" key={i} onClick={() => props.onChange(item)}>{item}</div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <input className="input-text" type="text" value={props.value} readOnly={true} />
            )}
        </>
    );
};

export const FormCompleted = () => {
    return (
        <div className="form-completed">
            <div className="content-layout">
                <div className="form-completed__title h1">Cпасибо за&nbsp;ваш отклик!</div>
                <div className="form-completed__text">Мы&nbsp;обязательно рассмотрим заявку и&nbsp;свяжемся с&nbsp;вами при положительном решении</div>
            </div>
        </div>
    );
};

export class FormInviteShort extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneCleave: null,
            errors: {
                fio: false,
                phone: false,
                vacancy: false
            }
        };
        this.changeVacancy = this.changeVacancy.bind(this);
        this.handleInputFocus = this.handleInputFocus.bind(this);
    }
    
    handleSubmit(e) {
        e.preventDefault();
        if (this.checkValidate()) this.props.onSubmit('short');
    }
    
    checkValidate() {
        const {fioValue, phoneValue} = this.props.formData;
        let valid = true,
            {errors} = this.state;
        if (!fioValue.trim().length) {
            errors.fio = true;
            valid = false;
        }
        if (phoneValue.length < 12) {
            errors.phone = true;
            valid = false;
        }
        this.setState({
            errors: errors
        });
        return valid;
    }
    
    handleInputChange(e) {
        const target = e.target,
            cleave = this.state[`${target.name}Cleave`];
        this.props.onSync({
            [`${target.name}Value`]: cleave
                ? cleave.getRawValue()
                : target.type === 'checkbox'
                    ? target.checked
                    : target.value
        });
    }
    
    handleInputFocus(e) {
        const name = e.target.name || e.target.getAttribute('name');
        if (!name) return;
        let {errors} = this.state;
        errors[name] = false;
        this.setState({
            errors: errors
        });
    }
    
    handleCleaveBlur(e) {
        const target = e.target,
            cleave = this.state[`${target.name}Cleave`];
        if (cleave.getRawValue().length < 12) {
            this.props.onSync({
                [`${target.name}Value`]: '+7'
            });
        }
    }
    
    handleCleaveInit(cleave) {
        this.setState({
            [`${cleave.element.name}Cleave`]: cleave
        });
    }
    
    changeVacancy(vacancyValue) {
        let vacancyType = null,
            syncData = {
                vacancyValue: vacancyValue
            };
        this.props.vacancy.list.forEach(item => {
            if (item.title === vacancyValue) vacancyType = item.type;
        });
        if (vacancyType === 'office') {
            syncData.addressValue = 'Любой офис';
        } else if (this.props.formData.addressValue === 'Любой офис') syncData.addressValue = 'Ресторан';
        this.props.onSync(syncData);
    }
    
    render() {
        const {errors} = this.state,
            {fioValue, phoneValue, vacancyValue} = this.props.formData;
        return (
            <form className={`${this.props.className}__form`} action="#" method="post" onSubmit={(e) => this.handleSubmit(e)}>
                <div className="input__wrap">
                    {errors.fio && <div className="input__error">{errorTypes.fio}</div>}
                    <input className="input-text" name="fio" value={fioValue} type="text" placeholder="ФИО"
                           onFocus={(e) => this.handleInputFocus(e)}
                           onChange={(e) => this.handleInputChange(e)} />
                </div>
                <div className="input__wrap">
                    {errors.phone && <div className="input__error">{errorTypes.phone}</div>}
                    <Cleave className="input-text" name="phone" value={phoneValue}
                            options={{
                                numericOnly: true,
                                blocks: [2, 0, 3, 0, 3, 2, 2],
                                delimiters: [' ', '(', ')', ' ', '-', '-'],
                                prefix: '+7'
                            }}
                            onInit={(e) => this.handleCleaveInit(e)}
                            onBlur={(e) => this.handleCleaveBlur(e)}
                            onFocus={(e) => this.handleInputFocus(e)}
                            onChange={(e) => this.handleInputChange(e)} />
                </div>
                <div className="input__wrap">
                    <InputSelect name="vacancy"
                                 value={vacancyValue}
                                 isSelect={this.props.vacancy ? this.props.vacancy.list.length : false}
                                 isError={errors.vacancy}
                                 list={this.props.vacancy ? this.props.vacancy.vacancyTitles : []}
                                 onClick={this.handleInputFocus}
                                 onChange={this.changeVacancy} />
                </div>
                <button className={`${this.props.className}__btn button`}>Отправить</button>
            </form>
        );
    }
}

export class FormInviteExtended extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneCleave: null,
            ageCleave: null,
            errors: {
                fio: false,
                phone: false,
                age: false,
                vacancy: false,
                address: false,
                email: false,
                agree: false
            }
        };
        this.figuresList = [
            [573, '↯'],
            [574, '□'],
            [575, '○'],
            [576, '△']
        ];
        this.changeVacancy = this.changeVacancy.bind(this);
        this.changeAddress = this.changeAddress.bind(this);
        this.changeCity = this.changeCity.bind(this);
        this.handleInputFocus = this.handleInputFocus.bind(this);
    }
    
    handleSubmit(e) {
        e.preventDefault();
        if (this.checkValidate()) this.props.onSubmit('complete');
    }
    
    checkValidate() {
        const {fioValue, phoneValue, ageValue, agreeValue, emailValue} = this.props.formData;
        let valid = true,
            {errors} = this.state;
        if (!fioValue.trim().length) {
            errors.fio = true;
            valid = false;
        }
        if (phoneValue.length < 12) {
            errors.phone = true;
            valid = false;
        }
        if (!ageValue.length) {
            errors.age = true;
            valid = false;
        }
        if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(emailValue)) {
            errors.email = true;
            valid = false;
        }
        if (!agreeValue) {
            errors.agree = true;
            valid = false;
        }
        this.setState({
            errors: errors
        });
        return valid;
    }
    
    handleInputChange(e) {
        const target = e.target,
            cleave = this.state[`${target.name}Cleave`];
        this.props.onSync({
            [`${target.name}Value`]: cleave
                ? cleave.getRawValue()
                : target.type === 'checkbox'
                    ? target.checked
                    : target.value
        });
    }
    
    handleInputFocus(e) {
        const name = e.target.name || e.target.getAttribute('name');
        if (!name) return;
        let {errors} = this.state;
        errors[name] = false;
        this.setState({
            errors: errors
        });
    }
    
    handleCleaveBlur(e) {
        const target = e.target,
            cleave = this.state[`${target.name}Cleave`];
        if (cleave.getRawValue().length < 12) {
            this.props.onSync({
                [`${target.name}Value`]: '+7'
            });
        }
    }
    
    handleCleaveInit(cleave) {
        this.setState({
            [`${cleave.element.name}Cleave`]: cleave
        });
    }
    
    changeVacancy(vacancyValue) {
        let vacancyType = null,
            syncData = {
                vacancyValue: vacancyValue
            };
        this.props.vacancy.list.forEach(item => {
            if (item.title === vacancyValue) vacancyType = item.type;
        });
        if (vacancyType === 'office') {
            syncData.addressValue = 'Любой офис';
        } else if (this.props.formData.addressValue === 'Любой офис') syncData.addressValue = 'Ресторан';
        this.props.onSync(syncData);
    }
    
    changeAddress(addressValue) {
        this.props.onSync({
            addressValue: addressValue
        });
    }
    
    changeCity(newCity) {
        this.props.onSync({
            localCity: newCity
        });
    }
    
    changeFigure(newFigure) {
        this.props.onSync({
            figureValue: newFigure[0],
            figureSymbol: newFigure[1]
        });
    }
    
    render() {
        const {errors} = this.state,
            {cityList, city} = this.props,
            {fioValue, phoneValue, ageValue, vacancyValue, addressValue, agreeValue, emailValue, figureValue, figureSymbol} = this.props.formData;
        const cityListItems = [];
        if (cityList.length > 1) {
            cityList.forEach(item => {
                if (item.en === city.en) return;
                cityListItems.push(
                    <div className="input-select__list-item" key={item.en}
                         onClick={() => this.changeCity({en: item.en, ru: item.ru})}>
                        {item.ru}
                    </div>
                );
            });
        }
        const figuresListItems = [];
        this.figuresList.forEach(item => {
            if (item[0] === figureValue) return;
            figuresListItems.push(
                <div className="input-select__list-item" key={item[0]}
                     onClick={() => this.changeFigure([item[0], item[1]])}>
                    {item[1]}
                </div>
            );
        });
        return (
            <form className={`${this.props.className}__form`} action="#" method="post"
                  onSubmit={(e) => this.handleSubmit(e)}>
                <div className="row justify-content-between align-items-start">
                    <div className="col-md-4 col-12">
                        <div className="input__wrap">
                            {errors.fio && <div className="input__error">{errorTypes.fio}</div>}
                            <input className="input-text" name="fio" value={fioValue} type="text" placeholder="ФИО"
                                   onFocus={(e) => this.handleInputFocus(e)}
                                   onChange={(e) => this.handleInputChange(e)} />
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="input__wrap">
                            {errors.phone && <div className="input__error">{errorTypes.phone}</div>}
                            <Cleave className="input-text" name="phone" value={phoneValue}
                                    options={{
                                        numericOnly: true,
                                        blocks: [2, 0, 3, 0, 3, 2, 2],
                                        delimiters: [' ', '(', ')', ' ', '-', '-'],
                                        prefix: '+7'
                                    }}
                                    onInit={(e) => this.handleCleaveInit(e)}
                                    onBlur={(e) => this.handleCleaveBlur(e)}
                                    onFocus={(e) => this.handleInputFocus(e)}
                                    onChange={(e) => this.handleInputChange(e)} />
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="input__wrap">
                            {errors.email && <div className="input__error">{errorTypes.email}</div>}
                            <input className="input-text" name="email" value={emailValue} type="email" placeholder="Эл. почта"
                                   onFocus={(e) => this.handleInputFocus(e)}
                                   onChange={(e) => this.handleInputChange(e)} />
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="input__wrap">
                            <InputSelect name="vacancy"
                                         value={vacancyValue}
                                         isSelect={this.props.vacancy ? this.props.vacancy.list.length : false}
                                         isError={errors.vacancy}
                                         list={this.props.vacancy ? this.props.vacancy.vacancyTitles : []}
                                         onClick={this.handleInputFocus}
                                         onChange={this.changeVacancy} />
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="input__wrap">
                            <div className={`input-select${(cityList.length < 2) ? ' input-select_disabled' : ''}`}>
                                <div className="input-select__text">{city.ru}</div>
                                <div className="input-select__list" data-custom_scroll="">
                                    <div>{cityListItems}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="input__wrap">
                            <InputSelect name="address"
                                         value={addressValue}
                                         isSelect={this.props.vacancy ? this.props.vacancy.list.length : false}
                                         isError={errors.address}
                                         list={this.props.vacancy ? this.props.vacancy.restaurantAddresses : []}
                                         onClick={this.handleInputFocus}
                                         onChange={this.changeAddress} />
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="input__wrap">
                            {errors.age && <div className="input__error">{errorTypes.age}</div>}
                            <Cleave className="input-text" name="age" value={ageValue} placeholder="Возраст"
                                    options={{
                                        numericOnly: true,
                                        blocks: [2]
                                    }}
                                    onInit={(e) => this.handleCleaveInit(e)}
                                    onFocus={(e) => this.handleInputFocus(e)}
                                    onChange={(e) => this.handleInputChange(e)} />
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="input__wrap">
                            <div className="input-select">
                                <div className="input-select__text">{figureSymbol}</div>
                                <div className="input-select__list" data-custom_scroll="">
                                    <div>{figuresListItems}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div style={{position: 'relative'}}>
                            {errors.agree && <div className="input__error">{errorTypes.agree}</div>}
                            <label className="input-checkbox">
                                <input type="checkbox" name="agree" checked={agreeValue}
                                       onFocus={(e) => this.handleInputFocus(e)}
                                       onChange={(e) => this.handleInputChange(e)} />
                                <div className="input-checkbox__checkbox">
                                    <IconCheck />
                                </div>
                                <div className="input-checkbox__text">
                                    <a href={AgreementPdf} target="_blank" rel="noopener noreferrer">Я даю согласие на обработку персональных данных</a>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="col-12 text-right">
                        <button className="button">Отправить</button>
                    </div>
                </div>
            </form>
        );
    }
}

export default FormInvite;
