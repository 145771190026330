import React from 'react';
import {Link} from 'react-router-dom';
import Tag from '../assets/img/tag_header.svg';
import {FormCompleted, FormInviteExtended} from './FormInvite';
import SocialLinks from './SocialLinks';

const Popups = props => {
    return (
        <>
            <a className="popup__bg" href="/" data-close_popup=""> </a>
            
            <PopupMenu title={props.menuContent.menu_title}
                       text={props.menuContent.menu_description}
                       socialLinks={props.socialLinks} />
            
            <PopupCityConfirm cityRu={props.cityDefine.ru}
                              onCityConfirm={props.onCityConfirm} />
            
            <PopupCitySelect className="popup popup-city-select popup-city-select_current"
                             isLocalChange={false}
                             city={props.currentCity}
                             cityList={props.cityAvailableList}
                             onCityChange={props.onCityChange} />
                             
            <PopupCitySelect className="popup popup-city-select popup-city-select_local"
                             isLocalChange={true}
                             city={props.localCity}
                             cityList={props.cityAvailableList}
                             onCityChange={props.onCityChange} />
            
            <PopupProfile city={props.localCity}
                          vacancy={props.vacancy}
                          formData={props.inviteFormData}
                          cityList={props.cityAvailableList}
                          onSync={props.onFormSync}
                          onSubmit={props.onFormSubmit} />
        </>
    );
};

class PopupMenu extends React.Component {
    shouldComponentUpdate() {
        return false;
    }
    
    render() {
        return (
            <div className="popup-menu">
                <div className="popup-menu__wrap" data-custom_scroll="">
                    <div className="content-layout">
                        <div className="popup-menu__row">
                            <div className="popup-menu-list">
                                <div className="popup-menu-list__item">
                                    <Link to="/about" data-close_popup="">О компании</Link>
                                </div>
                                <div className="popup-menu-list__item">
                                    <Link to="/advantages" data-close_popup="">Путь к достижениям</Link>
                                </div>
                                <div className="popup-menu-list__item">
                                    <Link to="/vacancy" data-close_popup="">Вакансии</Link>
                                </div>
                                <div className="popup-menu-list__item">
                                    <Link to="/corporate_culture" data-close_popup="">Корпоративная культура</Link>
                                </div>
                                <div className="popup-menu-list__item">
                                    <Link to="/contact" data-close_popup="">Контакты</Link>
                                </div>
                            </div>
                            <div className="popup-menu__body">
                                {this.props.title && <div className="popup-menu__title">{this.props.title}</div>}
                                {this.props.text && <div className="popup-menu__text">{this.props.text}</div>}
                                <SocialLinks className="popup-menu__social" links={this.props.socialLinks} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-menu__tag">
                    <img src={Tag} alt="" />
                </div>
            </div>
        );
    }
}

class PopupCityConfirm extends React.Component {
    shouldComponentUpdate(nextProps) {
        return this.props.cityRu !== nextProps.cityRu;
    }
    
    render() {
        return (
            <div className="popup popup-city-confirm">
                <div className="popup__close" data-close_popup="" />
                <div className="popup__wrap" data-custom_scroll="">
                    <div className="popup__title">Ваш город {this.props.cityRu}?</div>
                    <div className="popup__text">Информация будет показана для выбранного региона</div>
                    <div className="button button_bordered" data-close_popup="" onClick={() => this.props.onCityConfirm()}>Да, все верно</div>
                    <div className="button button_no-border" data-open_popup="city-select">Выбрать другой</div>
                </div>
            </div>
        );
    }
}

class PopupCitySelect extends React.Component {
    shouldComponentUpdate(nextProps) {
        return this.props.city.en !== nextProps.city.en;
    }
    
    parseCityList() {
        let cityList = [];
        this.props.cityList.forEach(item => {
            if (item.en === this.props.city.en) return;
            cityList.push(
                <div className="popup-city-select__city-item" key={item.en}>
                    <a href="/" data-close_popup="" onClick={() => this.props.onCityChange({en: item.en, ru: item.ru}, this.props.isLocalChange)}>
                        {item.ru}
                    </a>
                </div>
            );
        });
        let cityListPart = [],
            slicePos = Math.ceil(cityList.length / 2);
        cityListPart[0] = cityList.slice(0, slicePos);
        cityListPart[1] = cityList.slice(slicePos);
        return cityListPart;
    }
    
    render() {
        const cityList = this.parseCityList();
        return (
            <div className={this.props.className}>
                <div className="popup__close" data-close_popup="" />
                <div className="popup__wrap" data-custom_scroll="">
                    <div className="popup__title">выберите город</div>
                    <div className="popup__text">Информация будет показана для выбранного региона</div>
                    <div className="row text-center">
                        <div className="col-12 col-md-6">{cityList[0]}</div>
                        {cityList[1].length > 0 && <div className="col-12 col-md-6">{cityList[1]}</div>}
                    </div>
                </div>
            </div>
        );
    }
}

const PopupProfile = props => {
    return (
        <div className="popup-profile">
            <div className="content-layout">
                <div className="popup-profile__close" data-close_popup="">
                    <div />
                </div>
            </div>
            <div className="popup-profile__wrap" data-custom_scroll="">
                {props.formData.isComplete ? (<FormCompleted />) : (
                    <div className="content-layout">
                        <div className="popup-profile__title h1">Заполните анкету</div>
                        <FormInviteExtended className="popup-profile"
                                            vacancy={props.vacancy}
                                            city={props.city}
                                            formData={props.formData}
                                            cityList={props.cityList}
                                            onSync={props.onSync}
                                            onSubmit={props.onSubmit} />
                    </div>)}
            </div>
        </div>
    );
};

export default Popups;
