import React from 'react';
import axios from 'axios';
import {Route, Switch} from 'react-router-dom';
import cookie from 'react-cookies';
import PerfectScrollbar from 'perfect-scrollbar';
import MainPage from './components/pages/MainPage';
import AboutPage from './components/pages/AboutPage';
import CulturePage from './components/pages/CulturePage';
import AdvantagesPage from './components/pages/AdvantagesPage';
import VacancyPage from './components/pages/VacancyPage';
import VacancyDetailPage from './components/pages/VacancyDetailPage';
import ContactsPage from './components/pages/ContactsPage';
import NotFound from './components/pages/NotFound';
import Cover from './components/Cover';
import Header from './components/Header';
import Footer from './components/Footer';
import FooterSlider from './components/FooterSlider';
import FormInvite from './components/FormInvite';
import Popups from './components/Popups';
import {SendMetrics} from './helpers/Metrics';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.apiUrl = {
            prod: 'https://api.scrabota.ru/api/',
            test: 'https://api.sc.hpdev.ru/api/'
        };
        this.apiUrl.local = this.apiUrl.prod;
        this.hostName = 'scrabota.ru';
        this.env = 'prod';
        if (window.location.hostname.indexOf('sc.hpdev') !== -1) this.env = 'test';
        if (window.location.hostname.indexOf('localhost') !== -1) {
            this.env = 'local';
            this.hostName = 'localhost';
        }
        axios.defaults.baseURL = this.apiUrl[this.env];
        this.titleDefault = 'Своя Компания';
        document.title = this.titleDefault;
        this.elMetaDescription = document.querySelector('meta[name=description]');
        this.elMetaDescription.setAttribute('content', '');
        this.cityDefault = {en: 'yekaterinburg', ru: 'Екатеринбург'};
        this.cityDefine = this.cityDefault;
        this.state = {
            error: false,
            currentPage: null,
            cityAvailableList: null,
            currentCity: null,
            localCity: null,
            menuContent: null,
            socialLinks: null,
            pageContent: {},
            vacancy: {},
            inviteFormData: {
                fioValue: '',
                phoneValue: '',
                ageValue: '',
                vacancyValue: 'Вакансия',
                addressValue: 'Ресторан',
                emailValue: '',
                figureValue: '',
                figureSymbol: 'Выберите фигуру',
                agreeValue: false,
                isComplete: false
            }
        };
        this.declensionList = {
            'Москва': 'Москве',
            'Верхняя Пышма': 'Верхней Пышме',
            'Екатеринбург': 'Екатеринбурге',
            'Копейск': 'Копейске',
            'Магнитогорск': 'Магнитогорске',
            'Нижний Тагил': 'Нижнем Тагиле',
            'Новосибирск': 'Новосибирске',
            'Стерлитамак': 'Стерлитамаке',
            'Тюмень': 'Тюмени',
            'Уфа': 'Уфе',
            'Челябинск': 'Челябинске',
            'Якутск': 'Якутске',
            'Казань': 'Казани',
            'Барнаул': 'Барнауле'
        };
        this.currentCityCookie = cookie.load('currentCity');
        this.cookieExpires = 9776000;
        this.isShowConfirmPopup = false;
        this.elHtml = document.querySelector('html');
        this.initiatorPopupOpen = null;
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleCityConfirm = this.handleCityConfirm.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSubmitInviteForm = this.handleSubmitInviteForm.bind(this);
        this.handleSyncInviteForm = this.handleSyncInviteForm.bind(this);
        this.inputSelectInit();
        this.popupInit();
    }
    
    componentDidMount() {
        this.getCityList();
    }
    
    componentDidUpdate() {
        this.addCustomScroll();
    }
    
    getCityList() {
        axios.get('getcitylist').then(response => {
            if (response.data.success && response.data.data.citylist.length) {
                let cityAvailableList = [],
                    currentCity = null,
                    hostCity = window.location.hostname.split('.')[0].replace('-', '_'),
                    cityCertain = true;
                response.data.data.citylist.forEach(item => {
                    if (this.currentCityCookie && item.slug === this.currentCityCookie.en) currentCity = this.currentCityCookie;
                    if (item.slug === hostCity) hostCity = {en: item.slug, ru: item.title};
                    cityAvailableList.push({en: item.slug, ru: item.title});
                });
                if (this.env !== 'test') {
                    if (!hostCity.en) hostCity = this.cityDefault;
                    if (!currentCity) {
                        cityCertain = false;
                    } else if (currentCity.en !== hostCity.en) cityCertain = false;
                    currentCity = hostCity;
                }
                if (!currentCity) {
                    currentCity = this.cityDefault;
                    cityCertain = false;
                }
                this.saveCurrentCity(currentCity);
                this.setState({
                    cityAvailableList: cityAvailableList,
                    currentCity: currentCity,
                    localCity: currentCity,
                    menuContent: {
                        menu_title: response.data.data.menu_title,
                        menu_description: response.data.data.menu_description
                    },
                    socialLinks: response.data.data.social_url
                });
                this.getVacancy();
                if (!cityCertain) this.getCurrentCity();
            } else {
                this.setState({
                    error: true
                });
            }
        }).catch(error => {
            console.log(error);
            this.setState({
                error: true
            });
        });
    }
    
    getCurrentCity() {
        axios.get('getcitycode').then(response => {
            if (response.data.success) {
                const cityAvailableList = this.state.cityAvailableList;
                cityAvailableList.forEach(item => {
                    if (item.en === response.data.data) this.cityDefine = {en: item.en, ru: item.ru};
                });
                this.forceUpdate();
            }
            this.openPopup('city-confirm');
        }).catch(error => {
            console.log(error);
            this.openPopup('city-confirm');
        });
    }
    
    getPageContent(pageName, currentCityEn) {
        if (!pageName || pageName === 'notFound') return;
        let url = 'getpage/' + pageName;
        if (pageName === 'contacts') url += '/' + currentCityEn;
        axios.get(url).then(response => {
            if (response.data.success) {
                let pageContent = this.state.pageContent;
                if (pageName === 'contacts') {
                    pageContent[pageName + '_' + currentCityEn] = response.data.data.blocks.contacts;
                }
                if (!pageContent[pageName]) pageContent[pageName] = response.data.data;
                document.title = response.data.data.title || this.titleDefault;
                this.elMetaDescription.setAttribute('content', response.data.data['meta-description'] || '');
                this.setState({
                    pageContent: pageContent
                });
            } else {
                this.setState({
                    error: true
                });
            }
        }).catch(error => {
            console.log(error);
            this.setState({
                error: true
            });
        });
    }
    
    getVacancy() {
        axios.all([
            axios.get('getvacancies'),
            axios.get('getvacancycategory')
        ]).then(axios.spread((dataVacancy, dataCategory) => {
            if (dataVacancy.data.success) {
                if (!dataCategory.data.success) {
                    return this.setState({
                        error: true
                    });
                }
                const {cityAvailableList} = this.state;
                let vacancy = {};
                vacancy.all = this.parseVacancy(dataVacancy.data.data, dataCategory.data.data);
                dataVacancy.data.data.forEach(item => {
                    if (!vacancy[item.city]) {
                        vacancy[item.city] = [];
                        vacancy[item.city].push(item);
                    } else {
                        vacancy[item.city].push(item);
                    }
                });
                cityAvailableList.forEach(item => {
                    vacancy[item.en] = this.parseVacancy(vacancy[item.en] || [], dataCategory.data.data);
                });
                this.setState({
                    vacancy: vacancy
                });
                this.inviteFormCorrect(null, vacancy);
            }
        })).catch(error => {
            console.log(error);
            this.setState({
                error: true
            });
        });
    }
    
    parseVacancy(vacancyList, categoryList) {
        let data = {},
            uniqCategoryIdList = [],
            matchList = {};
        data.list = vacancyList;
        data.restaurantAddresses = [];
        data.vacancyTitles = [];
        data.matchList = {};
        data.category = {};
        data.category.list = [];
        data.category.matchList = {};
        if (!vacancyList.length) return data;
        categoryList.forEach((item, i) => matchList[item.id] = i);
        vacancyList.forEach((item, i) => {
            let category = categoryList[matchList[item.category]];
            data.matchList[item.id] = i;
            data.list[i].icon = category.icon;
            if (uniqCategoryIdList.indexOf(item.category) === -1) {
                uniqCategoryIdList.push(item.category);
                data.category.list.push(category);
            }
            if (data.vacancyTitles.indexOf(item.title) === -1) {
                data.vacancyTitles.push(item.title);
            }
            if (item.type === 'restaraun') {
                if (data.restaurantAddresses.indexOf(item.address) === -1) {
                    data.restaurantAddresses.push(item.address);
                }
            }
        });
        data.category.list.forEach((item, i) => data.category.matchList[item.id] = i);
        return data;
    }
    
    saveCurrentCity(city) {
        let expires = new Date(),
            opt = {path: '/'};
        expires.setTime(expires.getTime() + this.cookieExpires * 1000);
        opt.expires = expires;
        opt.domain = this.hostName;
        cookie.save('currentCity', city, opt);
        this.inviteFormCorrect(city);
    }
    
    inviteFormCorrect(newCity, newVacancy) {
        const {vacancy, localCity} = this.state;
        newCity = newCity || localCity;
        newVacancy = newVacancy || vacancy;
        if (!newCity) return;
        if (!newVacancy[newCity.en]) return;
        let {inviteFormData} = this.state;
        inviteFormData.vacancyValue = newVacancy[newCity.en].list.length > 0 ? 'Вакансия' : 'Любая вакансия';
        inviteFormData.addressValue = newVacancy[newCity.en].list.length > 0 ? 'Ресторан' : 'Любой ресторан';
        this.setState({
            inviteFormData: inviteFormData
        });
    }
    
    sendInviteForm(sendData, formType) {
        const {currentPage} = this.state;
        axios.get('sendmail', {params: sendData}).catch(error => console.log(error));
        SendMetrics(currentPage, formType, this.initiatorPopupOpen);
    }
    
    changeSubDomain(city) {
        window.location.href = window.location.href.replace(window.location.hostname, `${city.en.replace('_', '-')}.${this.hostName}`);
    }
    
    handleCityChange(newCity, local) {
        local = local || false;
        const {currentPage, pageContent} = this.state;
        if (local) {
            const {localCity} = this.state;
            if (localCity.en === newCity.en) return;
            this.setState({
                localCity: newCity
            });
        } else {
            const {currentCity} = this.state;
            if (currentCity.en === newCity.en) return;
            if (this.env !== 'test') return this.changeSubDomain(newCity);
            this.setState({
                currentCity: newCity,
                localCity: newCity
            });
            this.saveCurrentCity(newCity);
        }
        if (currentPage === 'contacts' && !pageContent[`contacts_${newCity.en}`]) {
            this.getPageContent('contacts', newCity.en);
        }
        this.inviteFormCorrect(newCity);
    }
    
    handleCityConfirm() {
        const currentCity = this.state.currentCity;
        if (currentCity.en === this.cityDefine.en) return;
        this.saveCurrentCity(this.cityDefine);
        if (this.env !== 'test') return this.changeSubDomain(this.cityDefine);
        this.setState({
            currentCity: this.cityDefine,
            localCity: this.cityDefine
        });
    }
    
    handlePageChange(pageName) {
        this.initiatorPopupOpen = null;
        this.setState({
            currentPage: pageName
        });
        if (pageName === 'notFound') return;
        const {currentCity, localCity} = this.state,
            pageContent = this.state.pageContent[`${pageName}${pageName === 'contacts' ? '_' + localCity.en : ''}`];
        if (!pageContent) {
            this.getPageContent(pageName, pageName === 'contacts' ? localCity.en : currentCity.en);
        } else {
            document.title = pageContent.title || this.titleDefault;
            this.elMetaDescription.setAttribute('content', pageContent['meta-description'] || '');
        }
        this.inputSelectCollapse();
        if (!this.isShowConfirmPopup) this.closePopup();
    }
    
    handleSubmitInviteForm(formType) {
        const {localCity, vacancy} = this.state,
            {fioValue, phoneValue, ageValue, vacancyValue, addressValue, emailValue, figureValue} = this.state.inviteFormData,
            sendData = {
                name: fioValue,
                phone_number: phoneValue,
                age: ageValue,
                vacancy: vacancyValue === 'Вакансия' ? 'Любая вакансия' : vacancyValue,
                address: addressValue === 'Ресторан' ? 'Любой ресторан' : addressValue,
                city: localCity.en,
                email: emailValue,
                symbol: figureValue
            };
        if (!vacancy.all) return;
        this.sendInviteForm(sendData, formType);
        this.setState({
            inviteFormData: {
                fioValue: '',
                phoneValue: '+7',
                ageValue: '',
                vacancyValue: vacancy[localCity.en].list.length > 0 ? 'Вакансия' : 'Любая вакансия',
                addressValue: vacancy[localCity.en].list.length > 0 ? 'Ресторан' : 'Любой ресторан',
                emailValue: '',
                figureValue: '',
                figureSymbol: 'Выберите фигуру',
                agreeValue: true,
                isComplete: true
            }
        });
    }
    
    handleSyncInviteForm(data) {
        let {inviteFormData} = this.state;
        for (let key in data) {
            if (!data.hasOwnProperty(key)) continue;
            if (key === 'localCity') {
                this.handleCityChange(data[key], true);
                continue;
            }
            inviteFormData[key] = data[key];
        }
        this.setState({
            inviteFormData: inviteFormData
        });
    }
    
    inputSelectInit() {
        window.addEventListener('click', (e) => {
            const elSelect = e.target.closest('.input-select');
            this.inputSelectCollapse(elSelect);
            if (!elSelect) return;
            (elSelect.getAttribute('data-expand') || elSelect.getAttribute('data-expand') === '')
                ? elSelect.removeAttribute('data-expand') : elSelect.setAttribute('data-expand', '');
            const elSelectScroll = elSelect.querySelector('[data-custom_scroll]');
            if (elSelectScroll && elSelectScroll.Scroll) elSelectScroll.Scroll.update();
        });
    }
    
    inputSelectCollapse(exclude) {
        document.querySelectorAll('.input-select').forEach(el => {
            if (el === exclude) return;
            el.removeAttribute('data-expand');
        });
    }
    
    popupInit() {
        this.scrollbarWidth = 0;
        window.addEventListener('click', (e) => {
            let el = e.target.closest('[data-toggle_popup]');
            if (el) {
                e.preventDefault();
                this.togglePopup(el.getAttribute('data-toggle_popup'), el);
                return;
            }
            el = e.target.closest('[data-open_popup]');
            if (el) {
                e.preventDefault();
                this.openPopup(el.getAttribute('data-open_popup'), el);
                return;
            }
            if (e.target.closest('[data-close_popup]')) {
                e.preventDefault();
                this.closePopup();
            }
        });
    }
    
    togglePopup(popupName, el) {
        this.elHtml.classList.contains(`popup_${popupName}`)
            ? this.closePopup() : this.openPopup(popupName, el);
    }
    
    openPopup(popupName, el) {
        this.closePopup();
        if (popupName === 'menu' || popupName === 'profile') {
            this.scrollbarWidth = window.innerWidth - this.elHtml.clientWidth;
            if (this.scrollbarWidth) {
                this.elHtml.style.paddingRight = `${this.scrollbarWidth}px`;
                document.querySelector('.header').style.paddingRight = `${this.scrollbarWidth}px`;
                document.querySelector('.popup-menu').style.paddingRight = `${this.scrollbarWidth}px`;
                document.querySelector('.popup-profile').style.paddingRight = `${this.scrollbarWidth}px`;
            }
        }
        if (popupName === 'city-confirm') this.isShowConfirmPopup = true;
        this.elHtml.classList.add(`popup_${popupName}`);
        this.elHtml.classList.add('show_popup');
        this.addCustomScroll();
        if (el) this.initiatorPopupOpen = el.getAttribute('data-kind');
    }
    
    closePopup() {
        this.initiatorPopupOpen = null;
        this.isShowConfirmPopup = false;
        if (this.scrollbarWidth) {
            this.elHtml.removeAttribute('style');
            document.querySelector('.header').removeAttribute('style');
            document.querySelector('.popup-menu').removeAttribute('style');
            document.querySelector('.popup-profile').removeAttribute('style');
        }
        this.scrollbarWidth = 0;
        if (!this.elHtml.classList.length) return;
        this.elHtml.className.split(' ').forEach(className => {
            if (~className.indexOf('popup')) this.elHtml.classList.remove(className);
        });
    }
    
    addCustomScroll() {
        document.querySelectorAll('[data-custom_scroll]').forEach(el => {
            if (el.Scroll) return el.Scroll.update();
            el.Scroll = new PerfectScrollbar(el);
        });
    }
    
    render() {
        const {error, currentPage, cityAvailableList, currentCity, localCity, menuContent, socialLinks, pageContent, vacancy, inviteFormData} = this.state;
        if (error) return <ErrorText>Произошла ошибка при загрузке данных<br />Попробуйте позже</ErrorText>;
        if (!cityAvailableList || !currentCity) return <Loading inline={false} />;
        return (
            <div className="content">
                <Route exact={true} path={['/', '/:part']} render={props =>
                    <Cover {...props} extra={{
                        content: pageContent[currentPage],
                        currentPage: currentPage,
                        localCity: localCity
                    }} />} />
                <Header cityAvailableList={cityAvailableList}
                        currentCity={currentCity}
                        onCityChange={this.handleCityChange} />
                <Switch>
                    <Route exact={true} path="/" render={props =>
                        <MainPage {...props} extra={{
                            currentCityDeclension: this.declensionList[localCity.ru] || localCity.ru,
                            localCity: localCity,
                            content: pageContent['main'],
                            vacancy: vacancy[localCity.en],
                            inviteFormData: inviteFormData,
                            onPageChange: this.handlePageChange,
                            onFormSync: this.handleSyncInviteForm,
                            onFormSubmit: this.handleSubmitInviteForm
                        }} />} />
                    <Route exact={true} path="/about" render={props =>
                        <AboutPage {...props} extra={{
                            content: pageContent['about'],
                            onPageChange: this.handlePageChange
                        }} />} />
                    <Route exact={true} path="/corporate_culture" render={props =>
                        <CulturePage {...props} extra={{
                            content: pageContent['culture'],
                            onPageChange: this.handlePageChange
                        }} />} />
                    <Route exact={true} path="/advantages" render={props =>
                        <AdvantagesPage {...props} extra={{
                            content: pageContent['advantages'],
                            onPageChange: this.handlePageChange
                        }} />} />
                    <Route exact={true} path="/vacancy" render={props =>
                        <VacancyPage {...props} extra={{
                            currentCityDeclension: this.declensionList[localCity.ru] || localCity.ru,
                            localCity: localCity,
                            content: pageContent['vacancy'],
                            vacancy: vacancy[localCity.en],
                            onPageChange: this.handlePageChange
                        }} />} />
                    <Route exact={true} path="/vacancy/:id" render={props =>
                        <VacancyDetailPage {...props} extra={{
                            vacancy: vacancy.all,
                            localCity: localCity,
                            onVacancySync: this.handleSyncInviteForm,
                            onPageChange: this.handlePageChange
                        }} />} />
                    <Route exact={true} path="/contact" render={props =>
                        <ContactsPage {...props} extra={{
                            content: pageContent[`contacts_${localCity.en}`],
                            onPageChange: this.handlePageChange
                        }} />} />
                    <Route render={props =>
                        <NotFound {...props} extra={{
                            onPageChange: this.handlePageChange
                        }} />} />
                </Switch>
                <Route exact={true} path={['/', '/:part']} render={props =>
                    <FormInvite {...props} extra={{
                        content: pageContent[currentPage],
                        vacancy: vacancy[localCity.en],
                        inviteFormData: inviteFormData,
                        onSync: this.handleSyncInviteForm,
                        onSubmit: this.handleSubmitInviteForm
                    }} />} />
                <FooterSlider content={pageContent[currentPage]}
                              currentPage={currentPage} />
                <Footer socialLinks={socialLinks} />
                <Popups cityAvailableList={cityAvailableList}
                        currentCity={currentCity}
                        localCity={localCity}
                        cityDefine={this.cityDefine}
                        menuContent={menuContent}
                        socialLinks={socialLinks}
                        vacancy={vacancy[localCity.en]}
                        inviteFormData={inviteFormData}
                        onCityChange={this.handleCityChange}
                        onCityConfirm={this.handleCityConfirm}
                        onFormSync={this.handleSyncInviteForm}
                        onFormSubmit={this.handleSubmitInviteForm} />
            </div>
        );
    }
}

const ErrorText = props => {
    return (
        <div className="error-text">
            <div className="content-layout">
                {props.children}
            </div>
        </div>
    );
};

export const Loading = props => {
    return (
        <div className={`loading${props.inline ? ' loading_inline' : ''}`}>
            <div />
            <div />
            <div />
            <div />
        </div>
    );
};

export default App;
