import React from 'react';
import {Loading} from '../../App';
import IconClock from '../../assets/img/icon_clock.svg';
import IconPhone from '../../assets/img/icon_phone.svg';
import IconMail from '../../assets/img/icon_mail.svg';

class ContactsPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.extra.onPageChange('contacts');
    }
    
    render() {
        const content = this.props.extra.content;
        if (!content) return <Loading inline={true} />;
        return (
            <section className="contacts-info">
                <div className="content-layout">
                    <div className="row justify-content-center">
                        {content.work_time &&
                        <div className="col-12 col-sm-6 col-md-4">
                            <div className="contacts-info-item">
                                <div className="contacts-info-item__icon circle-icon" style={{background: '#fd8d8c'}}>
                                    <img src={IconClock} alt="" />
                                </div>
                                <div className="contacts-info-item__title">Время работы</div>
                                <div className="contacts-info-item__text">{content.work_time}</div>
                            </div>
                        </div>}
                        {content.phone_number &&
                        <div className="col-12 col-sm-6 col-md-4">
                            <div className="contacts-info-item">
                                <div className="contacts-info-item__icon circle-icon" style={{background: '#ffc000'}}>
                                    <img src={IconPhone} alt="" />
                                </div>
                                <div className="contacts-info-item__title">Телефон</div>
                                <div className="contacts-info-item__text">{content.phone_number}</div>
                            </div>
                        </div>}
                        {content.email &&
                        <div className="col-12 col-sm-12 col-md-4">
                            <div className="contacts-info-item">
                                <div className="contacts-info-item__icon circle-icon" style={{background: '#66aaa9'}}>
                                    <img src={IconMail} alt="" />
                                </div>
                                <div className="contacts-info-item__title">Электронная почта</div>
                                <div className="contacts-info-item__text">
                                    <a href={`mailto:${content.email}`}>{content.email}</a>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactsPage;
