import React from 'react';
import Glide from '@glidejs/glide';

class GlideSlider extends React.Component {
    constructor(props) {
        super(props);
        this.data = {
            footer: {
                type: 'carousel',
                startAt: 0,
                perView: 3,
                focusAt: 'center',
                gap: 40,
                peek: -200,
                keyboard: false,
                breakpoints: {
                    665: {
                        perView: 2,
                        gap: 20,
                        focusAt: 0,
                        peek: {before: 0, after: -150}
                    }
                }
            },
            vacancy: {
                type: 'slider',
                bulletDecrease: 3,
                perView: 5,
                gap: 40,
                peek: {before: 40, after: -200},
                bound: true,
                rewind: false,
                keyboard: false,
                breakpoints: {
                    991: {
                        bulletDecrease: 1,
                        perView: 3,
                        gap: 30,
                        peek: {before: 30, after: -100}
                    },
                    665: {
                        bulletDecrease: 0,
                        perView: 2,
                        gap: 20,
                        peek: {before: 20, after: -150}
                    }
                }
            },
            culture: {
                type: 'carousel',
                startAt: 0,
                perView: 6,
                gap: 40,
                peek: -200,
                focusAt: 1,
                keyboard: false,
                breakpoints: {
                    991: {
                        perView: 5,
                        gap: 30
                    },
                    665: {
                        perView: 4,
                        gap: 10,
                        peek: -100
                    }
                }
            },
            about: {
                type: 'carousel',
                startAt: 0,
                perView: 6,
                gap: 40,
                peek: -200,
                focusAt: 1,
                keyboard: false,
                breakpoints: {
                    991: {
                        perView: 5,
                        gap: 30
                    },
                    665: {
                        perView: 4,
                        gap: 10,
                        peek: -100
                    }
                }
            },
            history: {
                type: 'slider',
                bulletDecrease: 3,
                blankBlock: false,
                startAt: 0,
                perView: 4,
                gap: 60,
                bound: true,
                rewind: false,
                breakpoints: {
                    991: {
                        bulletDecrease: 1,
                        blankBlock: true,
                        perView: 3,
                        gap: 40,
                        peek: {before: 20, after: -250}
                    },
                    665: {
                        bulletDecrease: 0,
                        blankBlock: true,
                        perView: 2,
                        gap: 20,
                        peek: {before: 20, after: -100}
                    }
                }
            },
            stories: {
                type: 'carousel',
                startAt: 0,
                perView: 6,
                focusAt: 1,
                gap: 40,
                peek: -200,
                keyboard: false,
                breakpoints: {
                    991: {
                        perView: 5,
                        gap: 30
                    },
                    665: {
                        perView: 3,
                        focusAt: 'center',
                        gap: 20,
                        peek: -150
                    }
                }
            },
            facts: {
                type: 'carousel',
                perView: 1,
                keyboard: false
            },
            story: {
                type: 'carousel',
                perView: 1,
                keyboard: false
            },
            photos: {
                type: 'carousel',
                perView: 1
            }
        };
        this.slider = null;
        this.timeoutResize = null;
        this.timeoutResizeDuration = 300;
        this.prevDevice = null;
        this.currentDevice = null;
        this.isBlankBlock = false;
    }
    
    componentDidMount() {
        this.sliderMount();
    }
    
    componentWillUnmount() {
        this.removeEvents();
    }
    
    componentDidUpdate() {
        if ((this.props.className === 'vacancy' && this.props.doUpdate) || this.props.className === 'footer' || this.props.className === 'photos') {
            this.sliderRebuild();
        }
    }
    
    checkDevice() {
        let device = 'desktop';
        if (window.innerWidth < 666) return 'phone';
        if (window.innerWidth < 992) return 'tablet';
        return device;
    }
    
    sliderMount() {
        if (this.slider) return;
        this.slider = new Glide(`.glide_${this.props.className}`, this.data[this.props.className]).mount();
        if (this.props.getSlider) this.props.getSlider(this.slider);
        this.addEvents();
        if (this.eventListenerResize) this.eventListenerResize();
    }
    
    sliderRebuild() {
        this.sliderDestroy();
        this.removeEvents();
        this.sliderMount();
    }
    
    sliderDestroy() {
        if (this.slider) this.slider.destroy();
        this.slider = null;
        this.prevDevice = null;
        this.currentDevice = null;
    }
    
    sliderDisabled() {
        this.slider.disable();
        document.querySelector(`.glide_${this.props.className}`).classList.add('glide_disabled');
    }
    
    sliderEnabled() {
        this.slider.enable();
        document.querySelector(`.glide_${this.props.className}`).classList.remove('glide_disabled');
    }
    
    correctBullets() {
        const elBullets = document.querySelectorAll(`.glide_${this.props.className} .glide__bullet`);
        elBullets.forEach(el => el.classList.remove('glide__bullet_last'));
        let bulletDecrease = this.data[this.props.className].bulletDecrease,
            blankBlock = this.data[this.props.className].blankBlock;
        if (this.currentDevice === 'tablet') {
            bulletDecrease = this.data[this.props.className].breakpoints[991].bulletDecrease;
            blankBlock = this.data[this.props.className].breakpoints[991].blankBlock;
        }
        if (this.currentDevice === 'phone') {
            bulletDecrease = this.data[this.props.className].breakpoints[665].bulletDecrease;
            blankBlock = this.data[this.props.className].breakpoints[665].blankBlock;
        }
        const bulletsCount = elBullets.length - bulletDecrease;
        if (bulletsCount < 2) {
            if (this.slider.index) this.slider.go('=0');
            setTimeout(() => {
                this.sliderDisabled();
            }, 510);
            this.sliderDisabled();
        } else {
            this.sliderEnabled();
            elBullets[(bulletsCount - 1)].classList.add('glide__bullet_last');
            if (this.slider.index > (bulletsCount - 1)) this.slider.go(`=${(bulletsCount - 1)}`);
            if (blankBlock !== undefined) this.checkBlankBlock(blankBlock);
        }
    }
    
    checkBlankBlock(blankBlock) {
        const elSlides = document.querySelector(`.glide_${this.props.className} .glide__slides`);
        if (blankBlock) {
            if (this.isBlankBlock) return;
            this.isBlankBlock = true;
            const elBlankBlock = document.createElement('div');
            elBlankBlock.classList.add('glide__slide', 'glide__slide_blank');
            elSlides.appendChild(elBlankBlock);
        } else {
            if (!this.isBlankBlock) return;
            this.isBlankBlock = false;
            elSlides.querySelector('.glide__slide_blank').remove();
        }
        this.sliderRebuild();
    }
    
    checkResize() {
        this.currentDevice = this.checkDevice();
        if (this.prevDevice === this.currentDevice) return;
        this.prevDevice = this.currentDevice;
        this.correctBullets();
    }
    
    addEvents() {
        if (this.data[this.props.className].bulletDecrease !== undefined && !this.eventListenerResize) {
            this.eventListenerResize = () => {
                clearTimeout(this.timeoutResize);
                this.timeoutResize = setTimeout(() => this.checkResize(), this.timeoutResizeDuration);
            };
            window.addEventListener('resize', this.eventListenerResize);
        }
    }
    
    removeEvents() {
        if (this.data[this.props.className].bulletDecrease !== undefined) {
            clearTimeout(this.timeoutResize);
            window.removeEventListener('resize', this.eventListenerResize);
            this.eventListenerResize = null;
        }
    }
    
    render() {
        return (
            <div className={`glide glide_${this.props.className}`}>
                {this.props.children}
            </div>
        );
    }
}

export default GlideSlider;
